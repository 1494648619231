import React from 'react'

const GlobalFilter = ({ filter, setFilter }) => {
	return (
		<div>
			Search:{'   '}
			<input
				value={filter || ''}
				onChange={(e) => setFilter(e.target.value)}
				style={{outline: 'none'}}
			/>
		</div>
	)
}

export default GlobalFilter
