/* eslint-disable default-case */
import moment from "moment"

const sortItems = (prev, curr, columnId) => {
	const prevValue = prev.original[columnId]
	const currValue = curr.original[columnId]

	if (prevValue === undefined || currValue === undefined) {
		return 0
	}

	if (columnId === "status" || columnId === "source") {
		if (prevValue[0].toLowerCase() > currValue[0].toLowerCase()) {
			return 1
		} else if (prevValue[0].toLowerCase() < currValue[0].toLowerCase()) {
			return -1
		} else {
			return 0
		}
	} else {
		const prevString = String(prevValue).toLowerCase()
		const currString = String(currValue).toLowerCase()
		if (prevString > currString) {
			return 1
		} else if (prevString < currString) {
			return -1
		} else {
			return 0
		}
	}
}

export const COLUMNS = (
	objectType,
	highLightedJobs = false,
	highLightedCompanies = false
) => {
	switch (objectType) {
		case "jobs":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "posted",
					accessor: "date",
					Cell: ({ value }) => {
						if (value !== "") return moment(value).format("DD-MM-YYYY")
						else return value
					},
				},
				{
					Header: "job title",
					accessor: "job_title",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
					Cell: ({ value, row }) => {
						if (
							row.original.job_is_highlighted !== undefined &&
							row.original.job_is_highlighted &&
							highLightedJobs
						) {
							return <span style={{ backgroundColor: "yellow" }}>{value}</span>
						}
						return <span>{value}</span>
					},
				},
				{
					Header: "job type",
					accessor: "job_type_obj.job_type",
					Cell: ({ value }) => {
						return <span>{value ? value : ""}</span>
					},
				},
				{
					Header: "company working",
					accessor: "company_working",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
					Cell: ({ value, row }) => {
						if (
							row.original.company_is_highlighted !== undefined &&
							row.original.company_is_highlighted &&
							highLightedCompanies
						) {
							return <span style={{ backgroundColor: "yellow" }}>{value}</span>
						}
						return <span>{value}</span>
					},
				},
				{
					Header: "company final",
					accessor: "company_final",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "sector",
					accessor: "sector.description",
					Cell: ({ value }) => {
						return <span>{value ? value : ""}</span>
					},
				},
				{
					Header: "location working",
					accessor: "location_working",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "location final",
					accessor: "location_final",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "salary",
					accessor: "salary",
				},
				{
					Header: "link",
					accessor: "url",
					Cell: ({ value }) => {
						return (
							<a href={value} target='_blank' rel='noopener noreferrer'>
								<u>view</u>
							</a>
						)
					},
				},
				{
					Header: "source",
					accessor: "source",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "status",
					accessor: "status",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "Highlighted job",
					accessor: "job_is_highlighted",
				},
				{
					Header: "HighLighted company",
					accessor: "company_is_highlighted",
				},
			]
		case "fuzzyjobs":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "group",
					accessor: "group",
				},
				{
					Header: "date",
					accessor: "date",
					Cell: ({ value }) => {
						if (value !== "") return moment(value).format("DD-MM-YYYY")
						else return value
					},
				},
				{
					Header: "job title",
					accessor: "job_title",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "job type",
					accessor: "job_type_obj.job_type",
				},
				{
					Header: "company working",
					accessor: "company_working",
				},
				{
					Header: "company final",
					accessor: "company_final",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "sector",
					accessor: "sector.description",
					Cell: ({ value }) => {
						return <span>{value ? value : ""}</span>
					},
				},
				{
					Header: "location working",
					accessor: "location_working",
				},
				{
					Header: "location",
					accessor: "location_final",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "link",
					accessor: "url",
					Cell: ({ value }) => {
						return (
							<a href={value} target='_blank' rel='noopener noreferrer'>
								<u>view</u>
							</a>
						)
					},
				},
				{
					Header: "source",
					accessor: "source",
				},
				{
					Header: "status",
					accessor: "status",
				},
				{
					Header: "Highlighted job",
					accessor: "job_is_highlighted",
				},
				{
					Header: "HighLighted company",
					accessor: "company_is_highlighted",
				},
			]
		case "jobtitlereplace":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "from",
					accessor: "from_",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "to",
					accessor: "to",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "jobtitleblack":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "black",
					accessor: "black",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "jobtitlewhite":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "white",
					accessor: "white",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "jobfiltering":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "rule",
					accessor: "rule",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "jobtypes":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "job type",
					accessor: "job_type",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "jobtypesreplace":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "job title",
					accessor: "job_title",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "job type",
					accessor: "job_type",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "sectors":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "description",
					accessor: "description",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "sic code",
					accessor: "sic_code",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "companies":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "company name",
					accessor: "company_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "company number",
					accessor: "number",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "website",
					accessor: "website",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
					Cell: ({ value }) => {
						return (
							<a href={value} target='_blank' rel='noopener noreferrer'>
								{value}
							</a>
						)
					},
				},
				{
					Header: "scrape on/off",
					accessor: "scrape",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
					Cell: ({ value }) => {
						return <span>{value === true ? "On" : "Off"}</span>
					},
				},
				{
					Header: "career page",
					accessor: "career_page",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "sector",
					accessor: "sector_obj",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
					Cell: ({ value }) => {
						return <span>{value ? value.description : ""}</span>
					},
				},
				{
					Header: "sic",
					accessor: "sic",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "regex",
					accessor: "regex",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "reports":
			return [
				{
					Header: "Id",
					accessor: "_id",
				},
				{
					Header: "company",
					accessor: "company_obj.company_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "website",
					accessor: "company_obj.website",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "career page",
					accessor: "company_obj.career_page",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "total items",
					accessor: "total_items",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "companyreplace":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "from",
					accessor: "from_",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "to",
					accessor: "to",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "companyblack":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "black",
					accessor: "black",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "companywhite":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "white",
					accessor: "white",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "location":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "location",
					accessor: "location_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "locationreplace":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "from",
					accessor: "from_",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "to",
					accessor: "to",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "recruiters":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "recruiter name",
					accessor: "recruiter_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "company",
					accessor: "company_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "employees":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "first name",
					accessor: "first_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "last name",
					accessor: "last_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "email",
					accessor: "email",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "role",
					accessor: "role",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "status",
					accessor: "status",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
		case "clients":
			return [
				{
					Header: "Id",
					accessor: "id",
				},
				{
					Header: "first name",
					accessor: "first_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "last name",
					accessor: "last_name",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "email",
					accessor: "email",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "company",
					accessor: "company",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "role",
					accessor: "role",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
				{
					Header: "status",
					accessor: "status",
					sortType: (prev, curr, columnId) => {
						return sortItems(prev, curr, columnId)
					},
				},
			]
	}
}
