import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'

const AddJobTitleBlackWhite = ({
	object,
	setAlert,
	addRecord,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [white, setWhite] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (white !== '') {
			const res = await addRecord(object, { white })
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing field!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Job Title White</label>
			<input
				type='text'
				name='white'
				value={white}
				onChange={(e) => setWhite(e.target.value)}
				required
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddJobTitleBlackWhite.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(
	AddJobTitleBlackWhite
)
