import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'

const AddRecruiter = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [recruiterName, setRecruiterName] = useState('')
	const [companyName, setCompanyName] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (recruiterName !== '') {
			const res = await addRecord(object, {
				recruiter_name: recruiterName,
				company_name: companyName,
			})
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing field!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Recruiter Name</label>
			<input
				type='text'
				name='recruiterName'
				value={recruiterName}
				onChange={(e) => setRecruiterName(e.target.value)}
				required
			></input>
			<label>Company Name</label>
			<input
				type='text'
				name='companyName'
				value={companyName}
				onChange={(e) => setCompanyName(e.target.value)}
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddRecruiter.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(AddRecruiter)
