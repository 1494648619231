import React, { Fragment, useEffect, useState } from "react"
import { CgTranscript } from "react-icons/cg"
import { RiLockPasswordLine } from "react-icons/ri"
import { Link, useLocation } from "react-router-dom"

const SettingOptions = () => {
	const location = useLocation()
	const [selected, setselected] = useState("")

	useEffect(() => {
		if (location.pathname.includes("/settings/changepassword")) {
			setselected("password")
		} else if (location.pathname.includes("/settings/automation")) {
			setselected("automation")
		} else if (location.pathname.includes("/settings/jobrulesautomation")) {
			setselected("rules_automation")
		} else if (location.pathname.includes("/settings/companiesscraper")) {
			setselected("companies_scraper")
		} else if (location.pathname.includes("/settings/changeemail")) {
			setselected("email")
		} else {
			setselected("")
		}
	}, [location])

	return (
		<Fragment>
			<Link
				onClick={() => setselected("automation")}
				className='settings-option'
				style={{ color: selected === "automation" ? "#0D6EFD" : "inherit" }}
				to={`/settings/automation`}
			>
				<CgTranscript size='16px' />
				<span>Automation</span>
			</Link>
			<Link
				onClick={() => setselected("rules_automation")}
				className='settings-option'
				style={{
					color: selected === "rules_automation" ? "#0D6EFD" : "inherit",
				}}
				to={`/settings/jobrulesautomation`}
			>
				<CgTranscript size='16px' />
				<span>Job Rules Automation</span>
			</Link>
			<Link
				onClick={() => setselected("companies_scraper")}
				className='settings-option'
				style={{
					color: selected === "companies_scraper" ? "#0D6EFD" : "inherit",
				}}
				to={`/settings/companiesscraper`}
			>
				<CgTranscript size='16px' />
				<span>Companies scraper</span>
			</Link>
			<Link
				onClick={() => setselected("password")}
				className='settings-option'
				style={{ color: selected === "password" ? "#0D6EFD" : "inherit" }}
				to={`/settings/changepassword`}
			>
				<RiLockPasswordLine size='16px' />
				<span>Change Password</span>
			</Link>
			{/* <Link
				onClick={() => setselected('email')}
				className='settings-option'
				style={{ color: selected === 'email' ? '#0D6EFD' : 'inherit' }}
				to={`/settings/changeemail`}
			>
				<HiOutlineMail size='16px' />
				<span>Change Email</span>
			</Link> */}
		</Fragment>
	)
}

export default SettingOptions
