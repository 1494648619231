import React, { Fragment } from "react"
import { useLocation } from "react-router-dom"
import Automation from "./Automation"
import ChangePassword from "./ChangePassword"
import CompaniesScraper from "./CompaniesScraper"
import JobRulesAutomation from "./JobRulesAutomation"

const SettingsContent = () => {
	const location = useLocation()
	const renderElement = () => {
		switch (location.pathname) {
			case "/settings/automation":
				return <Automation />
			case "/settings/jobrulesautomation":
				return <JobRulesAutomation />
			case "/settings/companiesscraper":
				return <CompaniesScraper />
			case "/settings/changepassword":
				return <ChangePassword />
			default:
				return <Fragment></Fragment>
		}
	}
	return <div className='settings-content'>{renderElement()}</div>
}

export default SettingsContent
