import moment from "moment"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { setAlert } from "../../../../actions/alert"
import { addRecordWithPagination } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"

const AddJob = ({ object, setAlert, addRecordWithPagination, handleClose }) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		job_title: "",
		job_type: "",
		company_working: "",
		company_final: "",
		date: "",
		location_working: "",
		location_final: "",
		salary: "",
		status: "pending",
		url: "",
		source: "direct",
	})
	const [jobTypeOptions, setJobTypeOptions] = useState([])

	useEffect(() => {
		const getOptions = async () => {
			try {
				const jobTypesRes = await ObjectServices.getJobTypes()
				setJobTypeOptions(jobTypesRes.data)
			} catch (err) {
				setAlert(err.message, "danger", "AddRecord")
			}
		}
		getOptions()
	}, [])

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const {
		company_working,
		company_final,
		date,
		job_title,
		job_type,
		location_final,
		location_working,
		salary,
		status,
		url,
		source,
	} = formData

	const prepareData = () => {
		return {
			company_working,
			company_final,
			date: date !== "" ? date : moment().format("YYYY-MM-D"),
			job_title,
			job_type,
			location_final,
			location_working,
			salary,
			status: [status],
			url,
			source: [source],
		}
	}

	const checkEmptyData = () => {
		let result = true
		if (url === "") {
			setAlert("Missing url field!", "danger", "AddRecord")
			result = false
		}

		if (status === "approved") {
			if (job_title === "") {
				setAlert("Missing job title field!", "danger", "AddRecord")
				result = false
			}
			if (date === "") {
				setAlert("Missing posting date field!", "danger", "AddRecord")
				result = false
			}
			if (company_final === "") {
				setAlert("Missing company final field!", "danger", "AddRecord")
				result = false
			}
			if (location_final === "") {
				setAlert("Missing location final field!", "danger", "AddRecord")
				result = false
			}
		}
		return result
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (checkEmptyData() === true) {
			const res = await addRecordWithPagination(object, prepareData())
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	const loadCompanyOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getCompanies({
					limit: 200,
					skip: 0,
					search_text: inputValue,
				})
				const options = response.data.results.map((company) => ({
					label: company.company_name,
					value: company.company_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "AddRecord")
				callback([])
			}
		}
	}

	const loadLocationOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getLocations({
					limit: 200,
					skip: 0,
					search_text: inputValue,
				})
				const options = response.data.results.map((location) => ({
					label: location.location_name,
					value: location.location_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "AddRecord")
				callback([])
			}
		}
	}

	const handleSelectChange = (selectedOption, name) => {
		if (selectedOption) {
			setFormData({
				...formData,
				[name]: selectedOption.value,
			})
		} else {
			setFormData({
				...formData,
				[name]: "",
			})
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Posting Date</label>
			<input
				type='date'
				name='date'
				value={date}
				onChange={onChange}
				required={status === "approved" ? true : false}
			></input>
			<label>Job Title</label>
			<input
				type='text'
				name='job_title'
				value={job_title}
				onChange={onChange}
				required={status === "approved" ? true : false}
			></input>
			<label>Job Type</label>
			<select name='job_type' value={job_type} onChange={onChange}>
				<option value=''>--Select job type--</option>
				{jobTypeOptions.map((e) => (
					<option key={e.id} value={e.id}>
						{e.job_type}
					</option>
				))}
			</select>
			<label>Company Working</label>
			<input
				type='text'
				name='company_working'
				value={company_working}
				onChange={onChange}
			></input>
			<label>Company Final</label>
			<AsyncSelect
				cacheOptions
				loadOptions={(inputValue, callback) =>
					loadCompanyOptions(inputValue, callback)
				}
				onChange={(selectedOption) =>
					handleSelectChange(selectedOption, "company_final")
				}
				isClearable
			/>
			<label>Location Working</label>
			<input
				type='text'
				name='location_working'
				value={location_working}
				onChange={onChange}
			></input>
			<label>Location Final</label>
			<AsyncSelect
				cacheOptions
				loadOptions={(inputValue, callback) =>
					loadLocationOptions(inputValue, callback)
				}
				onChange={(selectedOption) =>
					handleSelectChange(selectedOption, "location_final")
				}
				isClearable
			/>
			<label>Salary</label>
			<input
				type='text'
				name='salary'
				value={salary}
				onChange={onChange}
			></input>
			<label>Url</label>
			<input
				type='url'
				name='url'
				value={url}
				onChange={onChange}
				required={true}
			></input>
			<label>Source</label>
			<select name='source' onChange={onChange}>
				<option value='direct'>Direct</option>
				<option value='board'>Job board</option>
			</select>
			<label>Status</label>
			<select name='status' onChange={onChange}>
				<option value='pending'>Pending</option>
				<option value='approved'>Approved</option>
				<option value='expired'>Expired</option>
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddJob.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecordWithPagination: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecordWithPagination })(
	AddJob
)
