import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setAlert } from "../../../../actions/alert"
import { addRecordWithPagination } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"

const AddCompany = ({
	object,
	setAlert,
	addRecordWithPagination,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		company_name: "",
		number: "",
		website: "",
		career_page: "",
		sector: "",
		sic: "",
		regex: "",
		scrape: false,
	})
	const [sectorOptions, setSectorOptions] = useState([])

	const {
		company_name,
		number,
		website,
		career_page,
		sector,
		sic,
		regex,
		scrape,
	} = formData

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	// Populate sectors options
	useEffect(() => {
		async function getSectors() {
			try {
				const res = await ObjectServices.getSectors()
				if (res.data.length > 0) {
					setSectorOptions(res.data)
				}
			} catch (err) {
				setAlert(
					"An error occured while loading sectors!",
					"danger",
					"AddRecord"
				)
			}
		}
		getSectors()
	}, [])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (company_name !== "") {
			const res = await addRecordWithPagination(object, formData)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert("Missing company name!", "danger", "AddRecord")
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Company Name</label>
			<input
				type='text'
				name='company_name'
				value={company_name}
				onChange={onChange}
				required
			></input>
			<label>Company Number</label>
			<input
				type='text'
				name='number'
				value={number}
				onChange={onChange}
			></input>
			<label>Website</label>
			<input
				type='text'
				name='website'
				value={website}
				onChange={onChange}
			></input>
			<label>Career page</label>
			<input
				type='text'
				name='career_page'
				value={career_page}
				onChange={onChange}
			></input>
			<div className='filter-flex'>
				<label>Scrape on/off</label>
				<input
					className='switch-checkbox'
					id='switch-scrape'
					type='checkbox'
					checked={scrape}
					onChange={() => {
						setFormData({ ...formData, scrape: !scrape })
					}}
				/>
				<label className='switch-label' htmlFor='switch-scrape'>
					<span className='switch-button' />
				</label>
			</div>
			<label>Sector</label>
			<select name='sector' onChange={onChange} value={sector} required>
				<option value=''>--Select sector--</option>
				{sectorOptions.map((sector) => (
					<option key={sector.id} value={sector.id}>
						{sector.description}
					</option>
				))}
			</select>
			<label>SIC codes</label>
			<input type='text' name='sic' value={sic} onChange={onChange}></input>
			<label>Regex</label>
			<input type='text' name='regex' value={regex} onChange={onChange}></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddCompany.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecordWithPagination: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecordWithPagination })(
	AddCompany
)
