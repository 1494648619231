import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'
import ObjectServices from '../../../../services/object.service'

const AddJobTypesReplace = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [jobTypeOptions, setJobTypeOptions] = useState([])
	const [jobType, setJobType] = useState('')
	const [jobTitle, setJobTitle] = useState('')

	useEffect(() => {
		const getOptions = async () => {
			try {
				const res = await ObjectServices.getJobTypes()
				setJobTypeOptions(res.data)
			} catch (err) {
				setAlert(err.message, 'danger', 'AddRecord')
			}
		}
		getOptions()
	}, [])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (jobTitle !== '' && jobType !== '') {
			const res = await addRecord(object, { job_title: jobTitle, job_type: jobType })
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing fields!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Job Title</label>
			<input
				type='text'
				name='jobTitle'
				value={jobTitle}
				onChange={(e) => setJobTitle(e.target.value)}
			></input>
			<label>Job Type</label>
			<select value={jobType} onChange={(e) => setJobType(e.target.value)}>
				<option value='' disabled>--Select job type--</option>
				{jobTypeOptions.map((e) => (
					<option key={e.id} value={e.job_type}>
						{e.job_type}
					</option>
				))}
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddJobTypesReplace.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(
	AddJobTypesReplace
)
