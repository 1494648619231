import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { updateRecord } from '../../../../actions/record'

const UpdateSector = ({
	object,
	record,
	setAlert,
	updateRecord,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [description, setDescription] = useState('')
	const [sic_code, setSic_code] = useState('')

	useEffect(() => {
		if (record !== null && record !== '') {
			setDescription(record.description)
			setSic_code(record.sic_code)
		} else {
			navigate(-1)
		}
	}, [record])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (description !== '') {
			const res = await updateRecord(object, record.id, {
				description,
				sic_code,
			})
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing field!', 'danger', 'UpdateRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Description</label>
			<input
				type='text'
				name='description'
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				required
			></input>
			<label>SIC Code</label>
			<input
				type='text'
				name='sic_code'
				value={sic_code}
				onChange={(e) => setSic_code(e.target.value)}
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateSector.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
})

export default connect(mapStateToProps, { setAlert, updateRecord })(
	UpdateSector
)
