import axios from 'axios'
import TokenService from '../services/token.service'
import store from '../store'
import axiosInstance from './api'

const setup = () => {
	axiosInstance.interceptors.request.use(
		(config) => {
			const token = TokenService.getLocalAccessToken()
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token
			}
			return config
		},
		(error) => {
			return Promise.reject(error)
		}
	)
	axiosInstance.interceptors.response.use(
		(res) => {
			return res
		},
		async (err) => {
			const originalConfig = err.config
			if (
				err.response.status === 401 &&
				originalConfig.url === '/auth/refresh'
			) {
				return Promise.reject(err)
			}
			if (
				originalConfig.url !== '/users/login' &&
				originalConfig.url !== '/users/verify_otp/:otp' &&
				err.response
			) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry) {
					// handle infinite request loop with _retry flag
					originalConfig._retry = true
					try {
						const refresh_token = TokenService.getLocalRefreshToken()
						const config = {
							headers: { Authorization: 'Bearer ' + refresh_token },
						}
						const res = await axios.put(
							process.env.REACT_APP_HOST_URL + '/auth/refresh',
							{},
							config
						)
						const accessToken = res.data.response.access_token
						TokenService.setLocalAccessToken(accessToken)
						return axiosInstance(originalConfig)
					} catch (_error) {
						store.dispatch({ type: 'LOGOUT' })
						return Promise.reject(_error)
					}
				}
			}
			return Promise.reject(err)
		}
	)
}
export default setup
