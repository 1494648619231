import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth'
import filters from './filters'
import object from './object'
import record from './record'

export default combineReducers({
	alert,
	auth,
	object,
	record,
	filters,
})
