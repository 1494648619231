import {
	ADD_RECORD,
	CLEAR_ALL_RECORDS,
	DELETE_RECORDS,
	GET_RECORD,
	GET_RECORDS,
	GET_RECORDS_WITH_PAGINATION,
	INIT_RECORDS,
	PREPARE_EXPORT,
	RECORD_ERROR,
	SELECT_RECORDS,
	UPDATE_CLIENT_ACCESS,
	UPDATE_MANY_RECORDS,
	UPDATE_RECORD,
} from "../actions/types"

const initialState = {
	records: [],
	pagesCount: null,
	record: null,
	selectedRecords: [],
	exportRecords: {
		columns: [],
		data: [],
	},
	loading: true,
	error: {},
}

export default function foo(state = initialState, action) {
	const { type, payload } = action
	switch (type) {
		case ADD_RECORD:
			return {
				...state,
				records: payload,
				loading: true,
				error: {},
			}
		case GET_RECORDS:
			return {
				records: payload,
				pagesCount: null,
				record: null,
				selectedRecords: [],
				exportRecords: {
					columns: [],
					data: [],
				},
				loading: false,
				error: {},
			}
		case GET_RECORDS_WITH_PAGINATION:
			return {
				records: payload.results,
				record: null,
				pagesCount: payload.pages_count,
				selectedRecords: [],
				exportRecords: {
					columns: [],
					data: [],
				},
				loading: false,
				error: {},
			}
		case GET_RECORD:
			return {
				...state,
				record: payload,
				loading: false,
				error: {},
			}
		case SELECT_RECORDS:
			return {
				...state,
				selectedRecords: payload,
				error: {},
			}
		case INIT_RECORDS:
			return {
				records: [],
				pagesCount: null,
				record: null,
				selectedRecords: [],
				exportRecords: {
					columns: [],
					data: [],
				},
				loading: true,
				error: {},
			}
		case UPDATE_RECORD:
			return {
				...state,
				records: state.records.map((record) =>
					record.id === payload.id ? { ...record, ...payload } : record
				),
				record: { ...state.record, ...payload },
				loading: false,
				error: {},
			}
		case UPDATE_MANY_RECORDS:
			return {
				...state,
				records: state.records.map((record) =>
					payload.selected_records.includes(record.id)
						? { ...record, ...payload.payload }
						: record
				),
				loading: false,
				error: {},
			}
		case DELETE_RECORDS:
			return {
				...state,
				records: state.records.filter(
					(element) => !payload.includes(element.id)
				),
				selectedRecords: [],
				loading: false,
				error: {},
			}
		case CLEAR_ALL_RECORDS:
			return {
				...state,
				records: [],
				pagesCount: null,
				record: null,
				selectedRecords: [],
				exportRecords: {
					columns: [],
					data: [],
				},
				loading: false,
				error: {},
			}
		case PREPARE_EXPORT:
			return {
				...state,
				exportRecords: {
					columns: payload.columns,
					data: payload.data,
				},
			}
		case UPDATE_CLIENT_ACCESS:
			return {
				...state,
				loading: false,
				error: {},
			}
		case RECORD_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			}
		default:
			return state
	}
}
