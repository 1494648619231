import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { updateManyRecords, updateRecord } from '../../../../actions/record'

const UpdateUser = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	rowsToUpdate,
	selectedRecords,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		role: '',
		status: '',
	})

	const { first_name, last_name, email, role, status } = formData

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const prepareData = () => {
		return {
			first_name: first_name,
			last_name: last_name,
			email: email,
			role: role,
			status: status,
		}
	}

	// Get user data
	useEffect(() => {
		if (rowsToUpdate === 'one') {
			if (record !== null && record !== '') {
				setFormData({
					first_name: record.first_name,
					last_name: record.last_name,
					email: record.email,
					role: record.role,
					status: record.status,
				})
			} else {
				navigate(-1)
			}
		}
	}, [record])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rowsToUpdate === 'one') {
			if (
				first_name !== '' &&
				last_name !== '' &&
				email !== '' &&
				status !== '' &&
				role !== ''
			) {
				const res = await updateRecord(object, record.id, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert('Missing fields!', 'danger', 'UpdateRecord')
			}
		} else {
			const payload = { selected_records: selectedRecords, payload: formData }
			const res = await updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
					<label>First Name</label>
					<input
						type='text'
						name='first_name'
						value={first_name}
						onChange={handleChange}
						required={rowsToUpdate === 'one' ? true : false}
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
					<label>Last Name</label>
					<input
						type='text'
						name='last_name'
						value={last_name}
						onChange={handleChange}
						required={rowsToUpdate === 'one' ? true : false}
					/>
				</div>
			</div>
			<label>Email</label>
			<input
				type='email'
				name='email'
				value={email}
				onChange={handleChange}
				required={rowsToUpdate === 'one' ? true : false}
			/>
			<label>Role</label>
			<select name='role' onChange={handleChange}>
				{role === '' && (
					<option disabled selected value>
						{' '}
						-- select an option --{' '}
					</option>
				)}
				<option value='admin'>Admin</option>
				<option value='employee'>Employee</option>
			</select>
			<label>Status</label>
			<select name='status' onChange={handleChange}>
				{status === '' && (
					<option disabled selected value>
						{' '}
						-- select an option --{' '}
					</option>
				)}
				<option value='active'>Active</option>
				<option value='inactive'>Inactive</option>
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateUser.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
	selectedRecords: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateUser)
