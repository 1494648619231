import React, { useState } from 'react'

const SessionsTable = ({ data }) => {
	const [pageIndex, setPageIndex] = useState(0)
	const [pageSize, setPageSize] = useState(5)
	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })
	const [filters, setFilters] = useState({})

	const sortedData = () => {
		let sorted = [...data]

		if (sortConfig.key) {
			sorted = sorted.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'asc' ? -1 : 1
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'asc' ? 1 : -1
				}
				return 0
			})
		}

		if (Object.keys(filters).length > 0) {
			sorted = sorted.filter((item) =>
				Object.entries(filters).every(([key, value]) => {
					const cellValue = String(item[key])
					return cellValue.toLowerCase().includes(value.toLowerCase())
				})
			)
		}

		return sorted.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
	}

	const handleSort = (key) => {
		let direction = 'asc'
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc'
		}
		setSortConfig({ key, direction })
	}

	const handleFilterChange = (key, value) => {
		setFilters({ ...filters, [key]: value })
	}

	return (
		<div className='logs-sessions-container'>
			<div>
				<div className='filter-container'>
					<label>Filter by login time:</label>
					<input
						type='text'
						value={filters.login_time || ''}
						onChange={(e) => handleFilterChange('login_time', e.target.value)}
					/>
				</div>
				<div className='pagination-container'>
					<select
						value={pageSize}
						onChange={(e) => setPageSize(Number(e.target.value))}
					>
						{[5, 10].map((size) => (
							<option key={size} value={size}>
								Show {size}
							</option>
						))}
					</select>
					<select
						value={pageIndex}
						onChange={(e) => setPageIndex(Number(e.target.value))}
					>
						{[...Array(Math.ceil(data.length / pageSize))].map((val, i) => (
							<option key={i} value={i}>
								page {i + 1}
							</option>
						))}
					</select>
					<span>of {Math.ceil(data.length / pageSize)}</span>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th onClick={() => handleSort('login_time')}>Login time</th>
						<th onClick={() => handleSort('logout_time')}>Logout time</th>
						<th onClick={() => handleSort('duration')}>Duration (h)</th>
					</tr>
				</thead>
				<tbody>
					{sortedData().map((row) => (
						<tr key={row.id}>
							<td>{row.login_time}</td>
							<td>{row.logout_time}</td>
							<td>{row.duration}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

export default SessionsTable
