import PropTypes from 'prop-types'
import React from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Alert from '../layout/Alert'
import AddClient from './clients/add-forms/AddClient'
import AddCompany from './companies/add-forms/AddCompany'
import AddCompanyBlack from './companies/add-forms/AddCompanyBlack'
import AddCompanyReplace from './companies/add-forms/AddCompanyReplace'
import AddCompanyWhite from './companies/add-forms/AddCompanyWhite'
import AddUser from './employees/add-forms/AddUser'
import AddJob from './jobs/add-forms/AddJob'
import AddJobFiltering from './jobs/add-forms/AddJobFiltering'
import AddJobTitleBlack from './jobs/add-forms/AddJobTitleBlack'
import AddJobTitleReplace from './jobs/add-forms/AddJobTitleReplace'
import AddJobTitleWhite from './jobs/add-forms/AddJobTitleWhite'
import AddJobTypes from './jobs/add-forms/AddJobTypes'
import AddJobTypesReplace from './jobs/add-forms/AddJobTypesReplace'
import AddLocation from './locations/add-forms/AddLocation'
import AddLocationReplace from './locations/add-forms/AddLocationReplace'
import AddRecruiter from './recruiters/add-forms/AddRecruiter'
import AddSector from './sectors/add-forms/AddSector'

const AddRecord = ({ handleClose, object }) => {
	const navigate = useNavigate()

	const renderElement = () => {
		switch (object.path) {
			case 'clients':
				return <AddClient handleClose={handleClose} />
			case 'jobs':
				return <AddJob handleClose={handleClose} />
			case 'jobtitleblack':
				return <AddJobTitleBlack handleClose={handleClose} />
			case 'jobtitlewhite':
				return <AddJobTitleWhite handleClose={handleClose} />
			case 'jobtitlereplace':
				return <AddJobTitleReplace handleClose={handleClose} />
			case 'jobfiltering':
				return <AddJobFiltering handleClose={handleClose} />
			case 'jobtypes':
				return <AddJobTypes handleClose={handleClose} />
			case 'jobtypesreplace':
				return <AddJobTypesReplace handleClose={handleClose} />
			case 'sectors':
				return <AddSector handleClose={handleClose} />
			case 'companies':
				return <AddCompany handleClose={handleClose} />
			case 'companyblack':
				return <AddCompanyBlack handleClose={handleClose} />
			case 'companywhite':
				return <AddCompanyWhite handleClose={handleClose} />
			case 'companyreplace':
				return <AddCompanyReplace handleClose={handleClose} />
			case 'location':
				return <AddLocation handleClose={handleClose} />
			case 'locationreplace':
				return <AddLocationReplace handleClose={handleClose} />
			case 'recruiters':
				return <AddRecruiter handleClose={handleClose} />
			case 'employees':
				return <AddUser handleClose={handleClose} />
			default:
				return <div style={{ textAlign: 'center' }}>No such object</div>
		}
	}

	return (
		<div className='add-record-container'>
			<header>
				<h1>Add {object.name}</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(-1)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<Alert element='AddRecord' />
			{renderElement()}
		</div>
	)
}

AddRecord.prototype = {
	object: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps)(AddRecord)
