import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setAlert } from "../../../../actions/alert"
import { updateManyRecords, updateRecord } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"

const UpdateCompany = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	rowsToUpdate,
	selectedRecords,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		company_name: "",
		number: "",
		website: "",
		career_page: "",
		sector_obj: "",
		sic: "",
		regex: "",
		scrape: false,
	})
	const [sectorOptions, setSectorOptions] = useState([])

	const {
		company_name,
		number,
		website,
		career_page,
		sector_obj,
		sic,
		regex,
		scrape,
	} = formData

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		if (rowsToUpdate === "one") {
			if (record !== null && record !== "") {
				setFormData({
					...formData,
					company_name: record.company_name,
					number: record.number,
					website: record.website,
					career_page: record.career_page,
					sector_obj: record.sector_obj ? record.sector_obj : "",
					sic: record.sic,
					regex: record.regex,
					scrape: record.scrape,
				})
			} else {
				navigate(-1)
			}
		}
	}, [record, rowsToUpdate])

	// Populate sectors options
	useEffect(() => {
		async function getSectors() {
			try {
				const res = await ObjectServices.getSectors()
				if (res.data.length > 0) {
					setSectorOptions(res.data)
				}
			} catch (err) {
				setAlert(
					"An error occured while loading sectors!",
					"danger",
					"AddRecord"
				)
			}
		}
		getSectors()
	}, [])

	const prepareData = () => {
		return {
			company_name,
			number,
			website,
			career_page,
			sector: sector_obj?.id || "",
			sector_obj,
			sic,
			regex,
			scrape,
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rowsToUpdate === "one") {
			if (company_name !== "") {
				const res = await updateRecord(object, record.id, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert("Missing field!", "danger", "UpdateRecord")
			}
		} else {
			const payload = {
				selected_records: selectedRecords,
				payload: prepareData(),
			}
			const res = await updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	const handleSectorChange = (e) => {
		setFormData({
			...formData,
			sector_obj: sectorOptions.find((s) => s.id === e.target.value),
		})
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Company Name</label>
			<input
				type='text'
				name='company_name'
				value={company_name}
				onChange={onChange}
				required={rowsToUpdate === "one" ? true : false}
			></input>
			<label>Company Number</label>
			<input
				type='text'
				name='number'
				value={number}
				onChange={onChange}
			></input>
			<label>Website</label>
			<input
				type='text'
				name='website'
				value={website}
				onChange={onChange}
			></input>
			<label>Career page</label>
			<input
				type='text'
				name='career_page'
				value={career_page}
				onChange={onChange}
			></input>
			{rowsToUpdate === "one" && (
				<div className='filter-flex'>
					<label>Scrape on/off</label>
					<input
						className='switch-checkbox'
						id='switch-scrape'
						type='checkbox'
						checked={scrape}
						onChange={() => {
							setFormData({ ...formData, scrape: !scrape })
						}}
					/>
					<label className='switch-label' htmlFor='switch-scrape'>
						<span className='switch-button' />
					</label>
				</div>
			)}
			<label>Sector</label>
			<select
				name='sector'
				onChange={handleSectorChange}
				value={sector_obj?.id || ""}
			>
				<option value=''>--Select sector--</option>
				{sectorOptions.map((sector) => (
					<option key={sector.id} value={sector.id}>
						{sector.description}
					</option>
				))}
			</select>
			<label>SIC codes</label>
			<input type='text' name='sic' value={sic} onChange={onChange}></input>
			<label>Regex</label>
			<input type='text' name='regex' value={regex} onChange={onChange}></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateCompany.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
	rowsToUpdate: PropTypes.string.isRequired,
	selectedRecords: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateCompany)
