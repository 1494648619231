import api from "../utils/api"

const getAllRecords = (url) => {
	return api.get(url)
}

const getAllRecordsWithPagination = (url, params) => {
	return api.get(url, { params: params })
}

const getReportsWithFilters = (url, params) => {
	return api.get(url, { params: params })
}

const getRecord = (url) => {
	return api.get(url)
}

const addRecord = (url, formData) => {
	return api.post(url, JSON.stringify(formData))
}

const updateRecord = (url, formData) => {
	return api.put(url, JSON.stringify(formData))
}

const updateManyRecords = (url, formData) => {
	return api.put(url, JSON.stringify(formData))
}

const deleteRecords = (url, formData) => {
	return api.delete(url, { data: formData })
}

const updateClientAccess = (formData) => {
	return api.put("/settings/update", JSON.stringify(formData))
}

const filterRecords = (url, formData, params) => {
	return api.post(url, JSON.stringify(formData), { params: params })
}

const approveRecords = (url, formData) => {
	return api.put(url, JSON.stringify(formData))
}

const getFuzzyJobsRecords = (url, formData) => {
	return api.post(url, JSON.stringify(formData))
}

const clearAllRecords = (url) => {
	return api.delete(url, JSON.stringify({}))
}

const RecordServices = {
	getAllRecords,
	getAllRecordsWithPagination,
	addRecord,
	updateRecord,
	getRecord,
	deleteRecords,
	updateClientAccess,
	updateManyRecords,
	filterRecords,
	approveRecords,
	getFuzzyJobsRecords,
	clearAllRecords,
	getReportsWithFilters,
}

export default RecordServices
