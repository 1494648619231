import PropTypes from "prop-types"
import { useState } from "react"
import { connect } from "react-redux"
import { v4 as uuidv4 } from "uuid"
import { initFilters, updateFilters } from "../../../actions/filters"
import {
	filterRecords,
	getRecordsWithPagination,
} from "../../../actions/record"
import FilterRow from "./FilterRow"
import "./filters.css"

function JobFilters({
	object,
	handleClose,
	filterRecords,
	filtersList,
	updateFilters,
	initFilters,
	getRecordsWithPagination,
	applied,
	pageSize,
	pageIndex,
	searchText,
}) {
	const [filtersData, setFiltersData] = useState(filtersList)

	const addFilterRow = () => {
		setFiltersData([
			...filtersData,
			{
				id: uuidv4(),
				conjunction: "and",
				field: "date",
				operator: "is",
				value: "",
			},
		])
	}

	const removeFilterRow = (id) => {
		if (filtersData.length !== 1) {
			setFiltersData(filtersData.filter((e) => e.id !== id))
		}
	}

	const prepareData = () => {
		return {
			operands: filtersData.map((obj) => ({
				conjunction: obj.conjunction,
				field: obj.field,
				operator: obj.operator,
				value: obj.value,
			})),
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		const res = await filterRecords(object, prepareData(), {
			limit: pageSize,
			skip: pageIndex * pageSize,
			search_text: searchText,
		})
		if (res) {
			updateFilters(filtersData)
			handleClose(false)
		}
	}

	const handleCancelFilters = async () => {
		initFilters()
		if (applied) {
			await getRecordsWithPagination(
				object.findAllUrl,
				pageSize,
				pageIndex,
				searchText
			)
		}
		handleClose(false)
	}

	return (
		<div className='object-filters'>
			<form onSubmit={handleSubmit}>
				{filtersData.map((obj) => (
					<FilterRow
						key={obj.id}
						id={obj.id}
						removeFilterRow={removeFilterRow}
						addFilterRow={addFilterRow}
						setFiltersData={setFiltersData}
						filtersData={filtersData}
					/>
				))}
				<div className='space-between'>
					<input type='submit' value='Submit' className='submit-btn' />
					<button
						style={{ backgroundColor: "grey" }}
						className='submit-btn'
						onClick={handleCancelFilters}
					>
						Cancel filters
					</button>
				</div>
			</form>
		</div>
	)
}

JobFilters.proptype = {
	handleClose: PropTypes.func.isRequired,
	filterRecords: PropTypes.func.isRequired,
	object: PropTypes.object.isRequired,
	applied: PropTypes.bool.isRequired,
	filtersList: PropTypes.array.isRequired,
	updateFilters: PropTypes.func.isRequired,
	getRecordsWithPagination: PropTypes.func.isRequired,
	initFilters: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	filtersList: state.filters.filtersData,
	applied: state.filters.applied,
})

export default connect(mapStateToProps, {
	filterRecords,
	updateFilters,
	getRecordsWithPagination,
	initFilters,
})(JobFilters)
