import PropTypes from 'prop-types'
import React from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { getRecords } from '../../actions/record'
import api from '../../utils/api'
import Alert from '../layout/Alert'

const ImportFromDb = ({ handleClose, object, setAlert, getRecords }) => {
	const navigate = useNavigate()

	const handleImport = async () => {
		try {
			await api.post('/jobs/import_fuzzy_jobs')
			getRecords(object)
			handleClose(false)
		} catch (err) {
			setAlert(err.message, 'danger', 'ImportFromDb')
		}
	}

	return (
		<div className='delete-record-container'>
			<header>
				<h1>Import Jobs From Database</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<div>
				<Alert element='ImportFromDb' />
				<h2>Are you sure you want to import jobs from database ?</h2>
			</div>
			<footer>
				<button style={{ backgroundColor: '#6c757d' }} onClick={handleImport}>
					Confirm
				</button>
				<button
					style={{ backgroundColor: '#6c757d' }}
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					Cancel
				</button>
			</footer>
		</div>
	)
}

ImportFromDb.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	getRecords: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, getRecords })(
	ImportFromDb
)
