import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import Logo from '../../assets/images/Logo.png'
import AuthService from '../../services/auth.service'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'

const ForgotPassword = ({ auth, setAlert }) => {
	const navigate = useNavigate()
	const [email, setEmail] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		auth.isAuthenticated && navigate('/records')
	}, [auth.isAuthenticated])

	const handleSubmit = async (e) => {
		e.preventDefault()

		if (email !== '') {
			setLoading(true)
			try {
				const res = await AuthService.forgotPassword(email)
				setAlert(res.data.response, 'success', 'ForgotPassword', 10000)
			} catch (err) {
				setAlert(err.message, 'danger', 'ForgotPassword')
			} finally {
				setEmail('')
				setLoading(false)
			}
		} else {
			setAlert('Please enter your email', 'danger', 'ForgotPassword')
		}
	}

	return (
		<div className='auth-container'>
			{!auth.isAuthenticated && (
				<div className='auth-wrapper'>
					<div className='app-logo'>
						<img className='logo-img' src={Logo} alt='Logo'></img>
					</div>
					<h1 className='title'>Forgot your Password</h1>
					<form className='login-form' onSubmit={handleSubmit}>
						<Alert element='ForgotPassword' />

						{!loading ? (
							<>
								<label className='bold-label' htmlFor='email'>
									Email Address
								</label>
								<input
									type='email'
									name='email'
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								></input>

								<input type='submit' value='Send me a new password' />
								<br />
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<div></div>
									<button>
										<Link to='/login'>Back to login page</Link>
									</button>
								</div>
							</>
						) : (
							<Spinner />
						)}
					</form>
				</div>
			)}
		</div>
	)
}

ForgotPassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(ForgotPassword)
