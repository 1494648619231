import React from 'react'
import { FaBars, FaCube } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const MyObject = ({ handleClick, name, path, selected, nested, toggle }) => {
	const toggleIcon = () => {
		if (nested) {
			if (!toggle.toggled)
				return (
					<FaBars
						className='toggle-object'
						onClick={() => toggle.handleToggle(true)}
					/>
				)
			else
				return (
					<FaBars
						className='toggle-object-toggled'
						onClick={() => toggle.handleToggle(false)}
					/>
				)
		}
	}

	if (name !== 'Accounts') {
		return (
			<Link
				className={selected ? 'object selected' : 'object'}
				to={{ pathname: `/records/${path}` }}
				onClick={() => {
					handleClick(path)
				}}
			>
				<FaCube size='16px' className='nav-options-icon' />
				<span>{name}</span>
				{toggleIcon()}
			</Link>
		)
	} else {
		return (
			<div
				className='object'
				onClick={() => toggle.handleToggle(!toggle.toggled)}
			>
				<FaCube size='16px' className='nav-options-icon' />
				<span>{name}</span>
				{toggleIcon()}
			</div>
		)
	}
}

export default MyObject
