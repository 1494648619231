import AuthService from "../services/auth.service"
import { setAlert } from "./alert"
import {
	AUTH_ERROR,
	LOGIN_FAIL,
	LOGIN_SUCCESS,
	LOGOUT,
	REMEMBER_ME,
	USER_LOADED,
} from "./types"

// Load User
export const loadUser = () => (dispatch) => {
	dispatch({ type: USER_LOADED, payload: AuthService.getCurrentUser() })
}

// Init User
export const initUser = () => async (dispatch) => {
	const res = await AuthService.refreshToken()
	if (res === true) {
		dispatch(loadUser())
	} else {
		dispatch({ type: LOGIN_FAIL, payload: "Token not found or expired!" })
	}
}

// Login
export const login = (email, password, remember) => async (dispatch) => {
	try {
		const res = await AuthService.login(email, password)
		if (res.data.status === "success") {
			if (remember) dispatch({ type: REMEMBER_ME })
			return true
		}
		return false
	} catch (err) {
		dispatch({ type: LOGIN_FAIL, payload: err.message })
		dispatch(
			setAlert(
				err.response.data.response || "Invalid Credentials!",
				"danger",
				"Login"
			)
		)
	}
}

// Verify Otp
export const verifyOtp = (otp, email) => async (dispatch) => {
	try {
		const res = await AuthService.verifyOtp(otp, email)
		dispatch({ type: LOGIN_SUCCESS, payload: res.data.response })
		dispatch(loadUser())
	} catch (err) {
		dispatch({ type: LOGIN_FAIL, payload: err.message })
		dispatch(
			setAlert(err.response.data.response || "Invalid Otp!", "danger", "Otp")
		)
	}
}

// Logout
export const logout = (navigate) => async (dispatch) => {
	try {
		await Promise.all([
			AuthService.revokeRefreshToken(),
			AuthService.revokeAccessToken(),
		])
		dispatch({ type: LOGOUT })
		navigate("/login")
	} catch (err) {
		dispatch({ type: AUTH_ERROR })
		navigate("/login")
	} finally {
		AuthService.logout()
	}
}
