import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { initUser, logout } from './actions/auth'
import './App.css'
import ForgotPassword from './components/auth/ForgotPassword'
import Login from './components/auth/Login'
import ResetPassword from './components/auth/ResetPassword'
import Landing from './components/layout/Landing'
import PrivateRoute from './components/routing/PrivateRoute'

function App({ auth, initUser, logout }) {
	useEffect(() => {
		initUser()
	}, [initUser])

	window.onunload = () => {
		if (!auth.remember) {
			logout()
		}
	}

	return (
		<div className='App'>
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/forgotpassword' element={<ForgotPassword />} />
					<Route path='/users/password/reset/:token' element={<ResetPassword />} />
					<Route
						path='*'
						element={
							<PrivateRoute>
								<Landing />
							</PrivateRoute>
						}
					/>
				</Routes>
			</Router>
		</div>
	)
}

App.propTypes = {
	initUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps, { initUser, logout })(App)
