import api from "../utils/api"

const getCompanies = (params) => {
	return api.get("/companies/findall", { params: params })
}

const getSectors = () => {
	return api.get("/sectors/findall")
}

const getLocations = (params) => {
	return api.get("/locations/findall", { params: params })
}

const getJobs = () => {
	return api.get("/jobs/findall")
}

const getClientAccess = (id) => {
	return api.get(`/settings/find/${id}`)
}

const getJobTypes = () => {
	return api.get(`/job_types/findall`)
}

const ObjectServices = {
	getCompanies,
	getLocations,
	getJobs,
	getClientAccess,
	getSectors,
	getJobTypes,
}

export default ObjectServices
