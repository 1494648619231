import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert'
import AuthService from '../../services/auth.service'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'

const ChangePassword = ({ setAlert }) => {
	const [loading, setLoading] = useState(false)
	const [password, setPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true)
		if (password === '' || newPassword === '' || confirmPassword === '') {
			setAlert('Missing fields!', 'danger', 'ChangePassword')
			setLoading(false)
		} else {
			if (newPassword !== confirmPassword) {
				setAlert(
					`New password and confirm password doesn't match`,
					'danger',
					'ChangePassword'
				)
				setLoading(false)
			} else {
				try {
					const res = await AuthService.changePassword(prepareData())
					if (res.data.status === 'success') {
						setAlert(res.data.response, 'success', 'ChangePassword')
					}
				} catch (err) {
					setAlert('Change password failed!', 'danger', 'ChangePassword')
				} finally {
					setLoading(false)
					setPassword('')
					setNewPassword('')
					setConfirmPassword('')
				}
			}
		}
	}

	const prepareData = () => {
		return {
			password,
			new_password: newPassword,
			confirm_password: confirmPassword,
		}
	}

	return (
		<div className='changepassword_container'>
			<h1>Change Password</h1>
			{loading ? (
				<Spinner />
			) : (
				<form onSubmit={handleSubmit}>
					<Alert element='ChangePassword' />
					<label className='bold-label' htmlFor='password'>
						Password
					</label>
					<input
						type='password'
						name='password'
						required
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					></input>
					<label className='bold-label' htmlFor='password'>
						New Password
					</label>
					<input
						type='password'
						name='newPassword'
						required
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					></input>
					<label className='bold-label' htmlFor='password'>
						Confirm Password
					</label>
					<input
						type='password'
						name='confirmPassword'
						required
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					></input>
					<input type='submit' value='Change password'></input>
				</form>
			)}
		</div>
	)
}

export default connect(null, { setAlert })(ChangePassword)
