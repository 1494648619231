import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import RecordsOptions from '../records/RecordsOptions'
import SettingOptions from '../settings/SettingOptions'

const NavOptions = () => {
	return (
		<Fragment>
			<Routes>
				<Route
					path='/settings/*'
					element={
						<div className='nav-options nav-options-settings'>
							<SettingOptions />
						</div>
					}
				/>
				<Route
					path='/records/*'
					element={
						<div className='nav-options'>
							<RecordsOptions />
						</div>
					}
				/>
			</Routes>
		</Fragment>
	)
}

export default NavOptions
