import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRecords } from '../../actions/record'
import api from '../../utils/api'

const ImportRecord = ({ handleClose, object, getRecords }) => {
	const navigate = useNavigate()
	const [selectedFile, setSelectedFile] = useState(null)
	const [error, setError] = useState('')
	const [success, setSuccess] = useState('')

	const handleSelectFile = (e) => {
		e.preventDefault()
		if (e.target.files[0]) {
			if (e.target.files[0].size > 52428800) {
				setSelectedFile(null)
				setError('File size must be lower than 50 MB!')
				setSuccess('')
				setTimeout(() => {
					setError('')
				}, 4000)
			} else {
				setSuccess(e.target.files[0].name)
				setError('')
				getBase64(e.target.files[0])
					.then((data) => setSelectedFile(data.split(',')[1]))
					.catch((err) => setError(err))
			}
		}
	}

	const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}

	const handleTimport = async (e) => {
		e.preventDefault()
		setSuccess('')
		if (selectedFile !== null) {
			try {
				const result = await api.post(object.info.importUrl, {
					data: selectedFile,
				})
				setSuccess(result.data)
				setError('')
				getRecords(object.info)
				setTimeout(() => {
					setSuccess('')
				}, 4000)
			} catch (err) {
				setError(err.response.data)
				setSuccess('')
				setTimeout(() => {
					setError('')
				}, 4000)
			} finally {setSelectedFile(null)}
		} else {
			setError('You must select a csv file to upload first!')
			setSuccess('')
			setTimeout(() => {
				setError('')
			}, 4000)
		}
	}

	return (
		<div className='import-record-container'>
			<header>
				<h1>
					<FaCloudUploadAlt /> Import {object.name}
				</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.info.path}`)
					}}
				>
					<GrClose size='20px' />
				</button>
			</header>
			<section className='import-body'>
				<h1>Import a CSV File</h1>
				<div className='import-form-wrapper'>
					<div
						style={{ display: success !== '' ? 'block' : 'none' }}
						className='success-zone'
					>
						{success}
					</div>
					<div
						style={{ display: error !== '' ? 'block' : 'none' }}
						className='error-zone'
					>
						{error}
					</div>
					<p>Drag & drop or click to upload</p>
					<form id='import-form' onSubmit={handleTimport}>
						<div className='dropbox'>
							<input
								type='file'
								name='importRecord'
								onChange={handleSelectFile}
							></input>
							<FaCloudUploadAlt />
						</div>
						<button type='submit'>Upload</button>
					</form>
					<p style={{ fontSize: '14px', fontWeight: '400' }}>
						<i>50 MB file size limit</i>
					</p>
				</div>
			</section>
		</div>
	)
}

ImportRecord.prototype = {
	object: PropTypes.object.isRequired,
	getRecords: PropTypes.func.isRequired,
	handleClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object,
})

export default connect(mapStateToProps, { getRecords })(ImportRecord)
