import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { login } from '../../actions/auth'
import Logo from '../../assets/images/Logo.png'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'
import Otp from './Otp'

const Login = ({ login, setAlert, isAuthenticated, authLoading }) => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [rememberMe, setRememberMe] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()

	useEffect(() => {
		isAuthenticated && navigate('/records')
	}, [isAuthenticated])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (email !== '' && password !== '') {
			setLoading(true)
			const res = await login(email, password, rememberMe)
			if (res) {
				setModalIsOpen(true)
			}
			setLoading(false)
		} else {
			setAlert('Missing fields!', 'danger', 'Login')
			setLoading(false)
		}
	}

	return (
		<div className='auth-container'>
			{!authLoading && !isAuthenticated && (
				<Fragment>
					<ReactModal
						className='custom-modal-add'
						overlayClassName='modal-add-overlay'
						isOpen={modalIsOpen}
						shouldCloseOnOverlayClick={false}
						onRequestClose={() => setModalIsOpen(false)}
					>
						<Otp handleClose={setModalIsOpen} email={email} />
					</ReactModal>
					<div className='auth-wrapper'>
						<div className='app-logo'>
							<img className='logo-img' src={Logo} alt='Logo'></img>
						</div>
						<form className='login-form' onSubmit={handleSubmit}>
							<Alert element='Login' />
							<label className='bold-label' htmlFor='email'>
								Email Address
							</label>
							<input
								type='email'
								name='email'
								required
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							></input>
							<label className='bold-label' htmlFor='password'>
								Password
							</label>
							<input
								type='password'
								name='password'
								required
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							></input>
							<div className='space-btw'>
								<label
									htmlFor='remember'
									style={{
										fontSize: '14px',
										fontStyle: 'italic',
									}}
								>
									<input
										onChange={() => setRememberMe(!rememberMe)}
										type='checkbox'
										id='remember'
									></input>{' '}
									Keep logged in
								</label>
								<Link to='/forgotpassword'>Forgot password?</Link>
							</div>
							{!loading ? (
								<input type='submit' value='Sign In'></input>
							) : (
								<Spinner spinnerSize='100px' />
							)}
						</form>
					</div>
				</Fragment>
			)}
		</div>
	)
}

Login.propTypes = {
	login: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool.isRequired,
	authLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	authLoading: state.auth.loading,
})

export default connect(mapStateToProps, { login, setAlert })(Login)
