import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { setAlert } from "../../../../actions/alert"
import { updateRecord } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"

const UpdateClient = ({
	object,
	record,
	setAlert,
	updateRecord,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		company: "",
		role: "",
		status: "",
	})

	const { first_name, last_name, email, company, role, status } = formData

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	// Get client data
	useEffect(() => {
		if (record !== null && record !== "") {
			setFormData({
				first_name: record.first_name,
				last_name: record.last_name,
				email: record.email,
				company: record.company,
				role: record.role,
				status: record.status,
			})
		} else {
			navigate(-1)
		}
	}, [record])

	const prepareData = () => {
		return {
			first_name: first_name,
			last_name: last_name,
			company: company,
			email: email,
			role: role,
			status: status,
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (
			first_name !== "" &&
			last_name !== "" &&
			company !== "" &&
			email !== "" &&
			status !== "" &&
			role !== ""
		) {
			const res = await updateRecord(object, record.id, prepareData())
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert("Missing field!", "danger", "UpdateRecord")
		}
	}

	const loadCompanyOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getCompanies({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((company) => ({
					label: company.company_name,
					value: company.company_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "UpdateRecord")
				callback([])
			}
		}
	}

	const handleSelectChange = (selectedOption, name) => {
		if (selectedOption) {
			setFormData({
				...formData,
				[name]: selectedOption.value,
			})
		} else {
			setFormData({
				...formData,
				[name]: "",
			})
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
					<label>First Name</label>
					<input
						type='text'
						name='first_name'
						value={first_name}
						onChange={handleChange}
						required
					/>
				</div>
				<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
					<label>Last Name</label>
					<input
						type='text'
						name='last_name'
						value={last_name}
						onChange={handleChange}
						required
					/>
				</div>
			</div>
			<label>Email</label>
			<input
				type='email'
				name='email'
				value={email}
				onChange={handleChange}
				required
			/>
			<label>Company Name</label>
			<AsyncSelect
				cacheOptions
				loadOptions={loadCompanyOptions}
				onChange={(selectedOption) => handleSelectChange(selectedOption)}
				value={company}
				isClearable
			/>
			<label>Status</label>
			<select name='status' onChange={handleChange} required>
				<option value='active' selected={status === "active"}>
					Active
				</option>
				<option value='inactive' selected={status === "inactive"}>
					Inactive
				</option>
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateClient.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
})

export default connect(mapStateToProps, { setAlert, updateRecord })(
	UpdateClient
)
