import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaPlusCircle } from "react-icons/fa"
import { GrClose } from "react-icons/gr"
import { connect } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import AsyncSelect from "react-select/async"
import { setAlert } from "../../actions/alert"
import { getRecord, updateClientAccess } from "../../actions/record"
import ObjectServices from "../../services/object.service"
import Alert from "../layout/Alert"

const ClientAccess = ({
	handleClose,
	object,
	record,
	setAlert,
	getRecord,
	updateClientAccess,
}) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [first_name, setFirst_Name] = useState("")
	const [last_name, setLast_name] = useState("")
	const [is_premium, setIs_premium] = useState(false)
	const [job_titles, setJob_titles] = useState([])
	const [companies_name, setCompanies_name] = useState([])
	const [sectors, setSectors] = useState([])
	const [locations_name, setLocations_name] = useState([])
	const [days_number, setDays_number] = useState("")
	const [sectorOptions, setSectorOptions] = useState([])
	const [newjobTitle, setNewJobTitle] = useState("")

	// Get client data
	useEffect(() => {
		if (!location.state.id) {
			handleClose(false)
			navigate(`/records/${object.path}`)
			return
		}
		getRecord(object.findUrl, location.state.id)
	}, [])

	// Get options data
	useEffect(() => {
		if (record !== null) {
			setFirst_Name(record.first_name)
			setLast_name(record.last_name)
			fetchData()
		}
		async function fetchData() {
			try {
				const [fetchSect, fetchAccess] = await Promise.all([
					ObjectServices.getSectors(),
					ObjectServices.getClientAccess(record.id),
				])
				populateSectors(fetchSect.data)
				populateClientAccess(fetchAccess.data, fetchSect.data)
			} catch (err) {
				setAlert(err.message, "danger", "UpdateRecord")
				setTimeout(() => {
					navigate(`/records/${object.path}`)
					handleClose(false)
				}, 4000)
			}
		}
	}, [record])

	const populateSectors = (sectors) => {
		setSectorOptions(
			sectors.map((sector) => {
				return { label: sector.description, value: sector.id }
			})
		)
	}

	const populateClientAccess = (data, sectors) => {
		setJob_titles(
			data.job_titles !== undefined
				? data.job_titles.map((j) => {
						return { label: j, value: j }
				  })
				: []
		)
		setCompanies_name(
			data.companies_name !== undefined
				? data.companies_name.map((c) => {
						return { label: c, value: c }
				  })
				: []
		)
		if (data.sectors !== undefined && sectors.length > 0) {
			const uniqueSectors = sectors.filter((sector) => {
				if (data.sectors.includes(sector.id)) {
					return sector
				}
			})
			setSectors(
				uniqueSectors.map((sector) => {
					return {
						label: sector.description,
						value: sector.id,
					}
				})
			)
		}

		setLocations_name(
			data.locations_name !== undefined
				? data.locations_name.map((l) => {
						return { label: l, value: l }
				  })
				: []
		)
		setIs_premium(data.is_premium !== undefined && data.is_premium)

		setDays_number(
			data.days_number === undefined ||
				data.days_number === null ||
				data.days_number === -1
				? ""
				: data.days_number
		)
	}

	const prepareData = () => {
		return {
			locations_name: locations_name.map((l) => l.value),
			companies_name: companies_name.map((c) => c.value),
			sectors: sectors.length > 0 ? sectors.map((c) => c.value) : [],
			job_titles: job_titles.length > 0 ? job_titles.map((j) => j.value) : [],
			client_id: record.id,
			days_number: days_number < 1 || days_number === "" ? -1 : days_number,
			is_premium: is_premium,
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const res = await updateClientAccess(prepareData())
		if (res === true) {
			handleClose(false)
			navigate(`/records/${object.path}`)
		}
	}

	const handleNewJoB = () => {
		if (newjobTitle.trim() !== "" && newjobTitle.trim().length > 2) {
			setJob_titles([...job_titles, { label: newjobTitle, value: newjobTitle }])
			setNewJobTitle("")
		}
	}

	const loadCompanyOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getCompanies({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((company) => ({
					label: company.company_name,
					value: company.company_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "UpdateRecord")
				callback([])
			}
		}
	}

	const handleChangeCompany = (selectedOptions) => {
		if (selectedOptions) {
			setCompanies_name(selectedOptions)
		} else {
			setCompanies_name([])
		}
	}

	const loadLocationOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getLocations({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((location) => ({
					label: location.location_name,
					value: location.location_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "UpdateRecord")
				callback([])
			}
		}
	}

	const handleChangeLocation = (selectedOptions) => {
		if (selectedOptions) {
			setLocations_name(selectedOptions)
		} else {
			setLocations_name([])
		}
	}

	return (
		<div className='add-record-container'>
			<header>
				<h1>Update Client Access Permissions</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>

			<Alert element='UpdateRecord' />

			<form className='add-record-form' onSubmit={handleSubmit}>
				<label>Full Name</label>
				<input type='text' value={first_name + " " + last_name} disabled />

				<label>Jobs Titles</label>
				<Select
					components={makeAnimated()}
					className='options-container'
					onChange={setJob_titles}
					value={job_titles}
					options={[]}
					placeholder=''
					isMulti
					isSearchable
				/>
				<div className='flex-col'>
					<input
						type='text'
						placeholder='add new job title'
						value={newjobTitle}
						onChange={(e) => setNewJobTitle(e.target.value)}
					/>
					<FaPlusCircle fill='green' onClick={handleNewJoB} cursor='pointer' />
				</div>
				<label>Companies Names</label>
				<AsyncSelect
					cacheOptions
					loadOptions={loadCompanyOptions}
					onChange={(selectedOptions) => handleChangeCompany(selectedOptions)}
					value={companies_name}
					isClearable
					isMulti
					components={makeAnimated()}
				/>
				<label>Sectors</label>
				<Select
					components={makeAnimated()}
					className='options-container'
					onChange={setSectors}
					value={sectors}
					options={sectorOptions}
					placeholder='select sectors'
					noOptionsMessage={() => "no more sectors to choose from"}
					isMulti
					isSearchable
				/>
				<label>Locations Names</label>
				<AsyncSelect
					cacheOptions
					loadOptions={loadLocationOptions}
					onChange={(selectedOptions) => handleChangeLocation(selectedOptions)}
					value={locations_name}
					isClearable
					isMulti
					components={makeAnimated()}
				/>
				<div className='flex-col'>
					<label htmlFor='is_premium'>Premium Client</label>
					<input
						style={{ marginTop: "15px" }}
						id='is_premium'
						name='is_premium'
						type='checkbox'
						value={is_premium}
						onChange={() => setIs_premium(!is_premium)}
					/>
				</div>
				<label>Access by last (x) days:</label>
				<input
					name='days_number'
					type='number'
					placeholder='days number'
					min={1}
					value={days_number}
					onChange={(e) => setDays_number(e.target.value)}
				/>

				<input type='submit' value='Submit'></input>
			</form>
		</div>
	)
}

ClientAccess.prototype = {
	object: PropTypes.object.isRequired,
	record: PropTypes.object.isRequired,
	getRecord: PropTypes.func.isRequired,
	updateClientAccess: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
})

export default connect(mapStateToProps, {
	setAlert,
	updateClientAccess,
	getRecord,
})(ClientAccess)
