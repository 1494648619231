import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

const Alert = ({ alerts, element }) =>
	alerts !== null &&
	alerts.length > 0 &&
	alerts.map(
		(alert) =>
			alert.element === element && (
				<div key={alert.id} className={`alert alert-${alert.alertType}`}>
					{alert.msg}
				</div>
			)
	)

Alert.propTypes = {
	alerts: PropTypes.array.isRequired,
	element: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
	alerts: state.alert,
})

export default connect(mapStateToProps)(Alert)
