import PropTypes from "prop-types"
import React from "react"
import { GrClose } from "react-icons/gr"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import Alert from "../layout/Alert"
import FuzzyJobsFilters from "./Filters/FuzzyJobsFilters"
import JobFilters from "./Filters/JobFilters"

const AddFilters = ({
	handleClose,
	object,
	pageSize,
	pageIndex,
	searchText,
}) => {
	const navigate = useNavigate()

	const renderElement = () => {
		switch (object.path) {
			case "jobs":
				return (
					<JobFilters
						pageSize={pageSize}
						pageIndex={pageIndex}
						searchText={searchText}
						handleClose={handleClose}
					/>
				)
			case "fuzzyjobs":
				return <FuzzyJobsFilters handleClose={handleClose} />
			default:
				return <div style={{ textAlign: "center" }}>No such object</div>
		}
	}

	return (
		<div className='add-record-container'>
			<header>
				<h1>Edit Filters</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(-1)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<Alert element='AddFilters' />
			{renderElement()}
		</div>
	)
}

AddFilters.prototype = {
	object: PropTypes.object.isRequired,
	pageSize: PropTypes.number,
	pageIndex: PropTypes.number,
	searchText: PropTypes.string,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps)(AddFilters)
