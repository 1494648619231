import PropTypes from 'prop-types'
import React from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import api from '../../utils/api'
import Alert from '../layout/Alert'

const ExportToJobs = ({ handleClose, object, setAlert }) => {
	const navigate = useNavigate()

	const handleExport = async () => {
		try {
			await api.get('/export_fuzzy_jobs')
			handleClose(false)
		} catch (err) {
			setAlert(err.response.data, 'danger', 'ExportToJobs')
		}
	}

	return (
		<div className='delete-record-container'>
			<header>
				<h1>Export Fuzzy Jobs To Jobs Database</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<div>
				<Alert element='ExportToJobs' />
				<h2>Are you sure you want to export fuzzy jobs to jobs database ?</h2>
			</div>
			<footer>
				<button style={{ backgroundColor: '#6c757d' }} onClick={handleExport}>
					Confirm
				</button>
				<button
					style={{ backgroundColor: '#6c757d' }}
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					Cancel
				</button>
			</footer>
		</div>
	)
}

ExportToJobs.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert })(ExportToJobs)
