import React, { useEffect, useState } from 'react'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'

const FilterRow = ({
	id,
	removeFilterRow,
	addFilterRow,
	setFiltersData,
	filtersData,
}) => {
	const filterFields = [
		'date',
		'job_title',
		'company_working',
		'company_final',
		'location_working',
		'location_final',
		'link',
		'source',
		'status',
	]
	const [formData, setFormData] = useState(
		...filtersData.filter((e) => e.id === id)
	)

	const { conjunction, field, operator, value } = formData

	const handleChange = (e) => {
		if (e.target.name === 'field') {
			setFormData({
				...formData,
				value: '',
				[e.target.name]: e.target.value,
			})
		} else if (
			e.target.name === 'operator' &&
			(e.target.value === 'blank' || e.target.value === 'blank_not')
		) {
			setFormData({ ...formData, value: '', [e.target.name]: e.target.value })
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value })
		}
	}

	useEffect(() => {
		setFiltersData(filtersData.map((e) => (e.id === id ? formData : e)))
	}, [formData])

	return (
		<div className='row-filter'>
			{filtersData.findIndex((e) => e.id === id) === 0 ? (
				<span style={{ fontWeight: '500', fontSize: '0.9rem' }}>where</span>
			) : (
				<select name='conjunction' value={conjunction} onChange={handleChange}>
					<option value='and'>and</option>
					<option value='or'>or</option>
				</select>
			)}
			<select name='field' value={field} onChange={handleChange}>
				{filterFields.map((field, index) => (
					<option key={index} value={field}>
						{field}
					</option>
				))}
			</select>
			<select name='operator' value={operator} onChange={handleChange}>
				<option value='is'>is</option>
				<option value='is_not'>is not</option>

				{field !== 'status' && field !== 'source' && field !== 'date' && (
					<>
						<option value='blank'>is blank</option>
						<option value='blank_not'>is not blank</option>
						<option value='contains'>contains</option>
						<option value='contains_not'>does not contain</option>
						<option value='any'>is any</option>
					</>
				)}
				{field === 'date' && (
					<>
						<option value='before'>before</option>
						<option value='after'>after</option>
					</>
				)}
			</select>
			{field === 'date' ? (
				<input
					name='value'
					type='date'
					value={value}
					onChange={handleChange}
					disabled={operator === 'blank' || operator === 'blank_not'}
				></input>
			) : field === 'status' ? (
				<select name='value' value={value} onChange={handleChange} required>
					<option value=''>-- select an option --</option>
					<option value='pending'>pending</option>
					<option value='approved'>approved</option>
					<option value='expired'>expired</option>
				</select>
			) : field === 'source' ? (
				<select name='value' value={value} onChange={handleChange} required>
					<option value=''>-- select an option --</option>
					<option value='direct'>direct</option>
					<option value='board'>job board</option>
				</select>
			) : (
				<input
					style={{ textIndent: '0.2em' }}
					name='value'
					type='text'
					value={value}
					onChange={handleChange}
					disabled={operator === 'blank' || operator === 'blank_not'}
				/>
			)}

			<FaMinusCircle
				fill='red'
				className='filter-btn'
				onClick={() => removeFilterRow(id)}
			/>
			<FaPlusCircle
				fill='green'
				className='filter-btn'
				onClick={addFilterRow}
			/>
		</div>
	)
}

export default FilterRow
