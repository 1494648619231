import axios from 'axios'
import api from '../utils/api'
import TokenService from './token.service'

const login = async (email, password) => {
	const body = JSON.stringify({ email, password })
	return api.post('/users/login', body)
}

const verifyOtp = async (otp, email) => {
	const response = await api.get(`/users/verify_otp/${otp}/${email}`)
	if (response.data.status === 'success') {
		const { access_token, refresh_token } = response.data.response
		TokenService.setLocalAccessToken(access_token)
		TokenService.setLocalRefreshToken(refresh_token)
	}
	return response
}

const logout = () => {
	TokenService.removeTokens()
}

const getCurrentUser = () => {
	const accessToken = TokenService.getLocalAccessToken()
	const decode = (token) => {
		let base64Url = token.split('.')[1]
		let base64 = base64Url.replace('-', '+').replace('_', '/')
		return JSON.parse(window.atob(base64))
	}
	return decode(accessToken)
}

const revokeRefreshToken = () => {
	const config = {
		headers: {
			Authorization: `Bearer ${TokenService.getLocalRefreshToken()}`,
		},
	}

	return axios.put(
		process.env.REACT_APP_HOST_URL + '/users/auth/refresh_revoke',
		{},
		config
	)
}

const revokeAccessToken = async () => {
	return api.put('/users/auth/logout')
}

const refreshToken = async () => {
	const refreshToken = TokenService.getLocalRefreshToken()
	if (refreshToken !== null) {
		const config = {
			headers: {
				Authorization: `Bearer ${refreshToken}`,
			},
		}
		try {
			const res = await axios.put(
				process.env.REACT_APP_HOST_URL + '/auth/refresh',
				{},
				config
			)
			if (res.data.status === 'success') {
				TokenService.setLocalAccessToken(res.data.response.access_token)
				return true
			} else {
				TokenService.removeTokens()
				return false
			}
		} catch (err) {
			return false
		}
	}
	return false
}

const changePassword = async (formData) => {
	return api.put('/users/password/change', JSON.stringify(formData))
}

const forgotPassword = async (email) => {
	return api.put(`/users/password/forgot/${email}`)
}

const resetPassword = async (formData, token) => {
	return axios.put(
		process.env.REACT_APP_HOST_URL + `/users/password/reset/${token}`,
		formData
	)
}

const AuthService = {
	login,
	verifyOtp,
	logout,
	getCurrentUser,
	revokeAccessToken,
	revokeRefreshToken,
	refreshToken,
	changePassword,
	forgotPassword,
	resetPassword,
}

export default AuthService
