import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setAlert } from "../../../../actions/alert"
import { addRecordWithPagination } from "../../../../actions/record"

const AddLocation = ({
	object,
	setAlert,
	addRecordWithPagination,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [locationName, setLocationName] = useState("")

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (locationName !== "") {
			const res = await addRecordWithPagination(object, {
				location_name: locationName,
			})
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert("Missing field!", "danger", "AddRecord")
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Location</label>
			<input
				type='text'
				name='from'
				value={locationName}
				onChange={(e) => setLocationName(e.target.value)}
				required
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddLocation.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecordWithPagination: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecordWithPagination })(
	AddLocation
)
