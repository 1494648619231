import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import AsyncSelect from "react-select/async"
import { setAlert } from "../../../../actions/alert"
import { addRecord } from "../../../../actions/record"
import ObjectServices from "../../../../services/object.service"

const AddClient = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		company: "",
		password: "",
		repeatPassword: "",
		role: "client",
		status: "",
	})

	const {
		firstName,
		lastName,
		email,
		company,
		password,
		repeatPassword,
		role,
		status,
	} = formData

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const prepareData = () => {
		return {
			first_name: firstName,
			last_name: lastName,
			email: email,
			company: company,
			password: password,
			confirm_password: repeatPassword,
			role: role,
			status: status,
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (
			firstName !== "" &&
			lastName !== "" &&
			email !== "" &&
			company !== "" &&
			password !== "" &&
			repeatPassword !== "" &&
			status !== ""
		) {
			if (password === repeatPassword) {
				const res = await addRecord(object, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert(
					"Repeat password and Password doesn't match",
					"danger",
					"AddRecord"
				)
			}
		} else {
			setAlert("Missing fields!", "danger", "AddRecord")
		}
	}

	const handleSelectChange = (selectedOption, name) => {
		if (selectedOption) {
			setFormData({
				...formData,
				[name]: selectedOption.value,
			})
		} else {
			setFormData({
				...formData,
				[name]: "",
			})
		}
	}

	const loadCompanyOptions = async (inputValue, callback) => {
		if (inputValue.length > 3) {
			try {
				const response = await ObjectServices.getCompanies({
					limit: "",
					skip: "",
					searchText: inputValue,
				})
				const options = response.data.results.map((company) => ({
					label: company.company_name,
					value: company.company_name,
				}))
				callback(options)
			} catch (error) {
				setAlert(error.message, "danger", "AddRecord")
				callback([])
			}
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
					<label>First Name</label>
					<input
						type='text'
						name='firstName'
						value={firstName}
						onChange={handleChange}
						required
					/>
				</div>
				<div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
					<label>Last Name</label>
					<input
						type='text'
						name='lastName'
						value={lastName}
						onChange={handleChange}
						required
					/>
				</div>
			</div>
			<label>Email</label>
			<input
				type='email'
				name='email'
				value={email}
				onChange={handleChange}
				required
			/>
			<label>Password</label>
			<input
				type='password'
				name='password'
				value={password}
				onChange={handleChange}
				required
			/>
			<label>Repeat Password</label>
			<input
				type='password'
				name='repeatPassword'
				value={repeatPassword}
				onChange={handleChange}
				required
			/>
			<label>Company Name</label>
			<AsyncSelect
				cacheOptions
				loadOptions={(inputValue, callback) =>
					loadCompanyOptions(inputValue, callback)
				}
				onChange={(selectedOption) =>
					handleSelectChange(selectedOption, "company")
				}
				isClearable
			/>
			<label>Status</label>
			<select name='status' onChange={handleChange} required>
				<option disabled selected value>
					-- select an option --
				</option>
				<option key='active' value='active'>
					Active
				</option>
				<option key='inactive' value='inactive'>
					Inactive
				</option>
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddClient.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(AddClient)
