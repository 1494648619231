import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import {
	FaCloudDownloadAlt,
	FaCloudUploadAlt,
	FaCube,
	FaEdit,
	FaPlusSquare,
	FaTrashAlt,
} from 'react-icons/fa'
import { FcApproval } from 'react-icons/fc'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { selectObject } from '../../actions/object'
import AddFilters from './AddFilters'
import AddRecord from './AddRecord'
import ApproveJobs from './ApproveRecord'
import DeleteRecord from './DeleteRecord'
import ExportToJobs from './ExportToJobs'
import ImportFromDb from './ImportFromDb'
import ImportRecord from './ImportRecord'
import Records from './Records'
import UpdateRecord from './UpdateRecord'

ReactModal.setAppElement('#root')

const RecordsContent = ({
	object,
	selectObject,
	selectedRecords,
	exportRecords,
	recordsLength,
	userRole,
}) => {
	const { obj } = useParams()
	const navigate = useNavigate()
	const [addModalIsOpen, setAddModalIsOpen] = useState(false)
	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
	const [importModalIsOpen, setImportModalIsOpen] = useState(false)
	const [importFromDbModalIsOpen, setImportFromDbModalIsOpen] = useState(false)
	const [delModalIsOpen, setDelModalIsOpen] = useState(false)
	const [fuzzyFilterModalIsOpen, setFuzzyFilterModalIsOpen] = useState(false)
	const [approveModalIsOpen, setApproveModalIsOpen] = useState(false)
	const [exportToJobsModal, setExportToJobsModal] = useState(false)
	const [clearAll, setClearAll] = useState(false)
	const csvLink = useRef()

	useEffect(() => {
		if (object.path === null) selectObject(obj, navigate)
	}, [obj, object.path, selectObject, navigate])

	const handleExport = () => {
		if (exportRecords.columns.length && exportRecords.data.length) {
			setTimeout(() => {
				csvLink.current.link.click()
			}, 1)
		}
	}

	const handleCloseDeleteModal = () => {
		setDelModalIsOpen(false)
		setClearAll(false)
	}

	return (
		<div className='records-container'>
			<div className='records-nav'>
				<div className='object-menu'>
					<div className='object'>
						<FaCube size='16px' className='nav-options-icon' />
						<span>{object.name}</span>
					</div>
					<span className='record-span'>Records</span>
				</div>
				<div className='records-crud-menu'>
					{object.path !== 'fuzzyjobs' && object.addUrl && (
						<Link
							onClick={() => setAddModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/add`,
							}}
							className='add-user crud-option'
						>
							<FaPlusSquare size='16px' color='#009583' />
							<span>Add Record</span>
						</Link>
					)}
					{selectedRecords.length > 1 && object.updateManyUrl && (
						<Link
							onClick={() => setUpdateModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/update_many`,
							}}
							className='crud-option'
						>
							<FaEdit size='18px' />
							<span>Update</span>
						</Link>
					)}
					{selectedRecords.length > 0 && object.approveUrl && (
						<Link
							onClick={() => setApproveModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/approve`,
							}}
							className='crud-option'
						>
							<FcApproval size='18px' />
							<span>Approve</span>
						</Link>
					)}
					{object.path === 'fuzzyjobs' && (
						<Link
							onClick={() => setFuzzyFilterModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/fuzzyfilter`,
							}}
							className='import-from-db'
						>
							<FcApproval size='18px' />
							<span>Fuzzy Filter</span>
						</Link>
					)}
					{object.importUrl && object.importUrl !== null && (
						<Link
							onClick={() => setImportModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/import`,
							}}
							className='crud-option'
						>
							<FaCloudDownloadAlt size='18px' />
							<span>Import</span>
						</Link>
					)}
					{object.path === 'fuzzyjobs' && (
						<Link
							onClick={() => setImportFromDbModalIsOpen(true)}
							to={{
								pathname: `/records/${object.path}/importfromdb`,
							}}
							className='import-from-db'
						>
							<FaCloudDownloadAlt size='18px' />
							<span>Import From Jobs Db</span>
						</Link>
					)}
					{object.delUrl && (<Link
						onClick={() => setDelModalIsOpen(true)}
						to={{
							pathname: `/records/${object.path}/delete`,
						}}
						className='crud-option'
					>
						<FaTrashAlt size='16px' />
						<span>Delete</span>
					</Link>)}

					{exportRecords.data.length > 0 && (
						<>
							<Link
								onClick={handleExport}
								to={{
									pathname: `/records/${object.path}/clearAll`,
								}}
								className='crud-option'
							>
								<FaCloudUploadAlt size='16px' />
								<span>Export</span>
							</Link>
							<CSVLink
								data={exportRecords.data}
								headers={exportRecords.columns}
								filename={object.path + '-data.csv'}
								className='hidden'
								ref={csvLink}
								target='_blank'
							/>
						</>
					)}
					{userRole === 'admin' &&
						recordsLength > 0 &&
						(object.path === 'fuzzyjobs' || object.path === 'jobs') && (
							<Link
								onClick={() => {
									setClearAll(true)
									setDelModalIsOpen(true)
								}}
								to={{
									pathname: `/records/${object.path}/clearAll`,
								}}
								className='crud-option'
							>
								<FaTrashAlt size='16px' />
								<span>Clear all</span>
							</Link>
						)}
					{userRole === 'admin' &&
						recordsLength > 0 &&
						object.path === 'fuzzyjobs' && (
							<Link
								onClick={() => {
									setExportToJobsModal(true)
								}}
								to={{
									pathname: `/records/${object.path}/exportToJobs`,
								}}
								className='import-from-db'
							>
								<FaCloudUploadAlt size='16px' />
								<span>Export To Jobs</span>
							</Link>
						)}
				</div>
			</div>

			{/* Table and filter zone of records */}

			<Records />

			{/* Delete Record Modal */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={delModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => handleCloseDeleteModal}
			>
				<DeleteRecord
					handleClose={handleCloseDeleteModal}
					clearAll={clearAll}
				/>
			</ReactModal>

			{/* Add Record Modal */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={addModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setAddModalIsOpen(false)}
			>
				<AddRecord handleClose={setAddModalIsOpen} />
			</ReactModal>

			{/* Update Many Records Modal */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setUpdateModalIsOpen(false)}
				isOpen={updateModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<UpdateRecord handleClose={setUpdateModalIsOpen} rowsToUpdate='many' />
			</ReactModal>

			{/* Import Records Modal */}

			<ReactModal
				className='custom-modal-import'
				overlayClassName='modal-import-overlay'
				isOpen={importModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setImportModalIsOpen(false)}
			>
				<ImportRecord handleClose={setImportModalIsOpen} />
			</ReactModal>

			{/* Import Fuzzy Jobs from Db Modal */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={importFromDbModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setImportFromDbModalIsOpen(false)}
			>
				<ImportFromDb handleClose={setImportFromDbModalIsOpen} />
			</ReactModal>

			{/* Approve jobs modal */}
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={approveModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setApproveModalIsOpen(false)}
			>
				<ApproveJobs handleClose={setApproveModalIsOpen} />
			</ReactModal>

			{/* Fuzzy jobs filter modal */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={fuzzyFilterModalIsOpen}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setFuzzyFilterModalIsOpen(false)}
			>
				<AddFilters handleClose={setFuzzyFilterModalIsOpen} />
			</ReactModal>

			{/* Export Fuzzy Jobs To Jobs Database */}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				isOpen={exportToJobsModal}
				shouldCloseOnOverlayClick={false}
				onRequestClose={() => setExportToJobsModal(false)}
			>
				<ExportToJobs handleClose={setExportToJobsModal} />
			</ReactModal>
		</div>
	)
}

RecordsContent.prototype = {
	object: PropTypes.object.isRequired,
	selectObject: PropTypes.func.isRequired,
	selectedRecords: PropTypes.array.isRequired,
	exportRecords: PropTypes.object.isRequired,
	userRole: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	selectedRecords: state.record.selectedRecords,
	exportRecords: state.record.exportRecords,
	recordsLength: state.record.records.length,
	userRole: state.auth.user.role,
})

export default connect(mapStateToProps, { selectObject })(RecordsContent)
