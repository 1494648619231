const getLocalRefreshToken = () => {
	return localStorage.getItem('refresh_token')
}
const getLocalAccessToken = () => {
	return localStorage.getItem('access_token')
}
const setLocalAccessToken = (accessToken) => {
	localStorage.setItem('access_token', accessToken)
}

const setLocalRefreshToken = (refreshToken) => {
	localStorage.setItem('refresh_token', refreshToken)
}

const removeTokens = () => {
	localStorage.removeItem('access_token')
	localStorage.removeItem('refresh_token')
	// localStorage.clear()
}

const TokenService = {
	getLocalAccessToken,
	getLocalRefreshToken,
	setLocalAccessToken,
	setLocalRefreshToken,
	removeTokens,
}

export default TokenService
