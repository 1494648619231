import api from "../utils/api"

const startAutomation = (remove_dups_last_x_days) => {
	return api.get(`/automation`, { params: { remove_dups_last_x_days } })
}

const startRulesAutomation = () => {
	return api.get("/job_filtering/launch")
}

const startCompaniesScraping = () => {
	return api.get("/automation/scrape")
}

const SettingService = {
	startAutomation,
	startRulesAutomation,
	startCompaniesScraping,
}

export default SettingService
