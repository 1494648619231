import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import api from '../../../utils/api'
import Alert from '../../layout/Alert'
import Spinner from '../../layout/Spinner'
import LogsTable from './LogsTable'
import SessionsTable from './SessionsTable'
import './style.css'

const LogsModal = ({ handleClose, setAlert }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const [filterData, setFilterData] = useState({ month: '', year: '' })
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState({ logs: [], sessions: [] })

	const onChange = (e) => {
		setFilterData({ ...filterData, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		getAllLogs()
	}, [])

	const getAllLogs = async () => {
		try {
			const res = await api.get(`/sessions/logs/${location.state.id}`)
			setData(res.data)
		} catch (err) {
			setAlert(err.msg, 'danger', 'LogsModal')
		} finally {
			setLoading(false)
		}
	}

	const getLogsByMonthAndYear = async (month, year) => {
		try {
			setLoading(true)
			const res = await api.get(`/logs/${location.state.id}/${month}/${year}`)
			if (res.data.id) setData({ ...data, logs: [res.data] })
		} catch (err) {
			setAlert(err.response.data, 'danger', 'LogsModal')
		} finally {
			setLoading(false)
		}
	}

	const handleFilterSubmit = async (e) => {
		e.preventDefault()
		const month = filterData.month !== '' ? filterData.month : 0
		const year = filterData.year !== '' ? filterData.year : 0
		await getLogsByMonthAndYear(month, year)
	}

	return (
		<div className='logs-modal'>
			<header>
				<h1>Sessions logs</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate('/records/clients')
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<Alert element='LogsModal' />

			{loading ? (
				<Spinner />
			) : (
				<React.Fragment>
					<form className='logs-form' onSubmit={handleFilterSubmit}>
						<label htmlFor='year'>Year:</label>
						<input
							type='number'
							min={2023}
							max={2100}
							name='year'
							value={filterData.year}
							onChange={onChange}
						/>
						<label htmlFor='month'>Month:</label>
						<input
							type='number'
							min={1}
							max={12}
							name='month'
							value={filterData.month}
							onChange={onChange}
						/>
						<input type='submit' value='Filter Logs' />
					</form>

					<LogsTable data={data.logs} />

					<SessionsTable data={data.sessions} />
				</React.Fragment>
			)}
		</div>
	)
}

LogsModal.prototype = {
	handleClose: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { setAlert })(LogsModal)
