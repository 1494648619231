import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'

const AddJobFiltering = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [rule, setRule] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rule !== '') {
			const res = await addRecord(object, { rule })
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing field!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Rule</label>
			<input
				type='text'
				name='rule'
				value={rule}
				onChange={(e) => setRule(e.target.value)}
				required
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddJobFiltering.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(
	AddJobFiltering
)
