import { v4 as uuidv4 } from 'uuid'
import { INIT_FILTERS, UPDATE_FILTERS } from '../actions/types'

const initialState = {
	filtersData: [
		{
			id: uuidv4(),
			conjunction: 'and',
			field: 'date',
			operator: 'is',
			value: '',
		},
	],
	applied: false,
}

export default function foo(state = initialState, action) {
	const { type, payload } = action

	switch (type) {
		case INIT_FILTERS:
			return {
				filtersData: [
					{
						id: uuidv4(),
						conjunction: 'and',
						field: 'date',
						operator: 'is',
						value: '',
					},
				],
				applied: false,
			}
		case UPDATE_FILTERS:
			return {
				filtersData: payload,
				applied: true,
			}
		default:
			return state
	}
}
