import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CgPassword } from 'react-icons/cg'
import { FaUserCircle } from 'react-icons/fa'
import { IoIosLogOut } from 'react-icons/io'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { logout } from '../../actions/auth'
import Logo from '../../assets/images/WhiteLogo.png'

const Header = ({ logout }) => {
	const [isOpen, setIsOpen] = useState(false)
	const navigate = useNavigate()
	return (
		<header className='header'>
			<Link to='/records'>
				<img src={Logo} alt='logo icon' style={{ height: 'auto' }} />
			</Link>
			<button onClick={() => setIsOpen(!isOpen)}>
				<FaUserCircle size='17px' alignmentBaseline='center' />
			</button>
			<div
				className='overlay'
				onClick={() => setIsOpen(!isOpen)}
				style={{ display: !isOpen ? 'none' : 'block' }}
			>
				<div className='logout-container'>
					<div className='logout-arrow'></div>
					<Link className='logout-btn' to='/settings/changepassword'>
						<CgPassword /> <span>change password</span>
					</Link>
					<div className='logout-btn' onClick={(e) => logout(navigate)}>
						<IoIosLogOut /> <span>Sign out</span>
					</div>
				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	logout: PropTypes.func.isRequired,
}

export default connect(null, { logout })(Header)
