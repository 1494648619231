import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import RecordsContent from '../records/RecordsContent'
import SettingsContent from '../settings/SettingsContent'
import Header from './Header'
import NavOptions from './NavOptions'
import Sidebar from './Sidebar'

const Landing = ({ auth }) => {
	return (
		<Fragment>
			{!auth.loading && auth.isAuthenticated && (
				<Fragment>
					<Header />
					<div className='app-body'>
						<Sidebar />
						<NavOptions />
						<Routes>
							<Route path='/records/:obj/*' element={<RecordsContent />} />
							<Route path='/settings/*' element={<SettingsContent />} />
						</Routes>
					</div>
				</Fragment>
			)}
		</Fragment>
	)
}

Landing.prototype = {
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps, {})(Landing)
