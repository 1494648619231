import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert'
import SettingService from '../../services/setting.service'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'

const Automation = ({ setAlert }) => {
	const [loading, setLoading] = useState(false)
	const [days, setDays] = useState()

	const handleAutomation = async () => {
		try {
			setLoading(true)
			const res = await SettingService.startAutomation(days)
			if (res.data.status === 'success') {
				setAlert(res.data.response, 'success', 'Automation', 2000)
			}
		} catch (error) {
			setAlert('Automation failed!', 'danger', 'Automation')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='automation-wrapper'>
			<h1>Automation Tool</h1>
			<Alert element='Automation' />
			<p>Press the button to execute the automation.</p>
			<div className='automation-days'>
				<label>last x days:</label>
				<input
					value={days}
					type='number'
					min={0}
					onChange={(e) => setDays(e.target.value)}
					style={{width: '3.7rem', textIndent: '.2rem'}}
				/>
			</div>
			{loading ? (
				<Spinner />
			) : (
				<button onClick={handleAutomation}>Execute Automation</button>
			)}
		</div>
	)
}

export default connect(null, { setAlert })(Automation)
