import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { updateManyRecords, updateRecord } from '../../../../actions/record'

const UpdateCompanyBlack = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	selectedRecords,
	rowsToUpdate,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [black, setBlack] = useState('')

	useEffect(() => {
		if (rowsToUpdate === 'one') {
			if (record !== null && record !== '') {
				setBlack(record.black)
			} else {
				navigate(-1)
			}
		}
	}, [record])

	const prepareData = () => ({
		black: black,
	})

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rowsToUpdate === 'one') {
			if (black !== '') {
				const res = await updateRecord(object, record.id, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert('Missing field!', 'danger', 'UpdateRecord')
			}
		} else {
			const payload = {
				selected_records: selectedRecords,
				payload: prepareData(),
			}
			const res = await updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Company Black</label>
			<input
				type='text'
				name='black'
				value={black}
				onChange={(e) => setBlack(e.target.value)}
				required
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateCompanyBlack.prototype = {
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	object: PropTypes.object.isRequired,
	selectedRecords: PropTypes.array.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	rowsToUpdate: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateCompanyBlack)
