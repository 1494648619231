import React, { useState } from "react"
import { connect } from "react-redux"
import { setAlert } from "../../actions/alert"
import SettingService from "../../services/setting.service"
import Alert from "../layout/Alert"
import Spinner from "../layout/Spinner"

const CompaniesScraper = ({ setAlert }) => {
	const [loading, setLoading] = useState(false)
	const handleAutomation = async () => {
		try {
			setLoading(true)
			const res = await SettingService.startCompaniesScraping()
			if (res.data.status === "success") {
				setAlert(res.data.response, "success", "CompaniesScraper", 2000)
			}
		} catch (error) {
			setAlert("Scraping companies failed!", "danger", "CompaniesScraper")
		} finally {
			setLoading(false)
		}
	}

	return (
		<div className='automation-wrapper'>
			<h1>Companies Scraper</h1>
			<Alert element='CompaniesScraper' />
			<p>Press the button to execute the scraping.</p>
			{loading ? (
				<Spinner />
			) : (
				<button onClick={handleAutomation}>Execute Scraping</button>
			)}
		</div>
	)
}

export default connect(null, { setAlert })(CompaniesScraper)
