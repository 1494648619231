import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { updateManyRecords, updateRecord } from '../../../../actions/record'
import ObjectServices from '../../../../services/object.service'

const UpdateJobTypesReplace = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	selectedRecords,
	rowsToUpdate,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [jobType, setJobType] = useState('')
	const [jobTitle, setJobTitle] = useState('')
	const [jobTypeOptions, setJobTypeOptions] = useState([])

	useEffect(() => {
		if (rowsToUpdate === 'one') {
			if (record !== null && record !== '') {
				setJobType(record.job_type)
				setJobTitle(record.job_title)
			} else {
				navigate(-1)
			}
		}
	}, [record])

	useEffect(() => {
		const getOptions = async () => {
			try {
				const res = await ObjectServices.getJobTypes()
				setJobTypeOptions(res.data)
			} catch (err) {
				setAlert(err.message, 'danger', 'UpdateRecord')
			}
		}
		getOptions()
	}, [])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rowsToUpdate === 'one') {
			if (jobType !== '' && jobTitle !== '') {
				const res = await updateRecord(object, record.id, {
					job_type: jobType,
					job_title: jobTitle,
				})
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert('Missing fields!', 'danger', 'UpdateRecord')
			}
		} else {
			const payload = {
				selected_records: selectedRecords,
				payload: { job_type: jobType, job_title: jobTitle },
			}
			const res = await updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Job Title</label>
			<input
				type='text'
				name='jobTitle'
				value={jobTitle}
				onChange={(e) => setJobTitle(e.target.value)}
				required
			></input>
			<label>Job Type</label>
			<select value={jobType} onChange={(e) => setJobType(e.target.value)}>
				<option value='' disabled>
					--Select job type--
				</option>
				{jobTypeOptions.map((e) => (
					<option key={e.id} value={e.job_type}>
						{e.job_type}
					</option>
				))}
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateJobTypesReplace.prototype = {
	object: PropTypes.object.isRequired,
	record: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	rowsToUpdate: PropTypes.string.isRequired,
	selectedRecords: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateJobTypesReplace)
