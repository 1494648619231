import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import Logo from '../../assets/images/Logo.png'
import AuthService from '../../services/auth.service'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'

const ResetPassword = ({ auth, setAlert }) => {
	const navigate = useNavigate()
	const { token } = useParams()
	const [formData, setFormData] = useState({
		new_password: '',
		confirm_password: '',
	})
	const [loading, setLoading] = useState(false)
	const { new_password, confirm_password } = formData

    useEffect(() => {
		if (token === null || token === undefined || token === '') {
			navigate('/login')
		}
	}, [])

	useEffect(() => {
		auth.isAuthenticated && navigate('/records')
	}, [auth.isAuthenticated])

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (new_password !== '' && confirm_password !== '') {
			if (new_password !== confirm_password) {
				setAlert(`passwords don't match`, 'danger', 'ResetPassword')
			} else {
				setLoading(true)
				try {
					const res = await AuthService.resetPassword(formData, token)
					setAlert(res.data.response, 'success', 'ResetPassword')
					setTimeout(() => navigate('/login'), 1500)
				} catch (err) {
					setAlert(err.message, 'danger', 'ResetPassword')
				} finally {
					setFormData({
						new_password: '',
						confirm_password: '',
					})
					setLoading(false)
				}
			}
		} else {
			setAlert('Please fill the required fields', 'danger', 'ResetPassword')
		}
	}

	return (
		<div className='auth-container'>
			{!auth.isAuthenticated && (
				<div className='auth-wrapper'>
					<div className='app-logo'>
						<img className='logo-img' src={Logo} alt='Logo'></img>
					</div>
					<h1 className='title'>Reset Password</h1>
					<form className='login-form' onSubmit={handleSubmit}>
						<Alert element='ResetPassword' />

						{!loading ? (
							<>
								<label className='bold-label' htmlFor='email'>
									New Password
								</label>
								<input
									type='password'
									name='new_password'
									required
									value={new_password}
									onChange={handleChange}
								></input>
								<label className='bold-label' htmlFor='email'>
									Confirm Password
								</label>
								<input
									type='password'
									name='confirm_password'
									required
									value={confirm_password}
									onChange={handleChange}
								></input>

								<input type='submit' value='Confirm' />
								<br />
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<div></div>
									<button>
										<Link to='/login'>Back to login page</Link>
									</button>
								</div>
							</>
						) : (
							<Spinner />
						)}
					</form>
				</div>
			)}
		</div>
	)
}

ResetPassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
	auth: state.auth,
})

export default connect(mapStateToProps, { setAlert })(ResetPassword)
