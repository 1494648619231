import PropTypes from 'prop-types'
import React from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { approveRecord } from '../../actions/record'
import Alert from '../layout/Alert'

const ApproveRecord = ({
	handleClose,
	object,
	selectedRecords,
	approveRecord,
}) => {
	const navigate = useNavigate()

	const handleApprove = async () => {
		const res = await approveRecord(object, selectedRecords)
		if (res === true) {
			setTimeout(() => {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}, 3000)
		}
	}

	return (
		<div className='delete-record-container'>
			<header>
				<h1>Approve {object.name}</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(-1)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<div>
				<Alert element='ApproveRecord' />
				<h2>Are you sure you want to approve the selected records ?</h2>
			</div>

			<footer>
				<button
					style={{ background: '#f47d00', border: '#f47d00' }}
					onClick={handleApprove}
				>
					Confirm
				</button>

				<button
					style={{ backgroundColor: '#6c757d' }}
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					Cancel
				</button>
			</footer>
		</div>
	)
}

ApproveRecord.prototype = {
	object: PropTypes.object.isRequired,
	selectedRecords: PropTypes.array.isRequired,
	approveRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	selectedRecords: state.record.selectedRecords,
	object: state.object.info,
})

export default connect(mapStateToProps, { approveRecord })(
	ApproveRecord
)
