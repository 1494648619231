export const SET_ALERT = "SET_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const REMEMBER_ME = "REMEMBER_ME"
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT = "LOGOUT"
export const ACCOUNT_DELETED = "ACCOUNT_DELETED"
export const ADD_RECORD = "ADD_RECORD"
export const UPDATE_RECORD = "UPDATE_RECORD"
export const UPDATE_MANY_RECORDS = "UPDATE_MANY_RECORDS"
export const UPDATE_CLIENT_ACCESS = "UPDATE_CLIENT_ACCESS"
export const DELETE_RECORDS = "DELETE_RECORDS"
export const GET_RECORDS = "GET_RECORDS"
export const GET_RECORD = "GET_RECORD"
export const GET_RECORDS_WITH_PAGINATION = "GET_RECORDS_WITH_PAGINATION"
export const SELECT_RECORDS = "SELECT_RECORDS"
export const INIT_RECORDS = "INIT_RECORDS"
export const CLEAR_ALL_RECORDS = "CLEAR_ALL_RECORDS"
export const RECORD_ERROR = "RECORD_ERROR"
export const SELECT_OBJECT = "SELECT_OBJECT"
export const CLEAR_OBJECT = "CLEAR_OBJECT"
export const OBJECT_ERROR = "OBJECT_ERROR"
export const PREPARE_EXPORT = "PREPARE_EXPORT"
export const INIT_FILTERS = "INIT_FILTERS"
export const UPDATE_FILTERS = "UPDATE_FILTERS"
