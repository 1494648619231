import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { FaFilter } from "react-icons/fa"
import { GrFormNext, GrFormPrevious } from "react-icons/gr"
import ReactModal from "react-modal"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
	useFilters,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table"
import {
	getRecordsWithPagination,
	prepareExport,
} from "../../../actions/record"
import Spinner from "../../layout/Spinner"
import AddFilters from "../AddFilters"
import ClientAccess from "../ClientAccess"
import UpdateRecord from "../UpdateRecord"
import LogsModal from "../logs/LogsModal"
import Checkbox from "./Checkbox"
import { COLUMNS } from "./Columns"
import "./table.css"

const TableWithPagination = ({
	object,
	records,
	setSelection,
	prepareExport,
	getRecordsWithPagination,
	pagesCount,
	pageSize,
	setPageLimit,
	pageIndex,
	setPageIndex,
	rangeIndex,
	setRangeIndex,
	searchText,
	setSearchText,
	isLoading,
}) => {
	const [highLightJobs, setHighLightJobs] = useState(true)
	const [highLightCompanies, setHighLightCompanies] = useState(true)
	const [filterHighlightedJobs, setFilterHighLightedJobs] = useState(false)
	const [filterHighlightedCompanies, setFilterHighLightedCompanies] =
		useState(false)
	const [columns, setColumns] = useState([])

	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
	const [accessModalIsOpen, setAccessModalIsOpen] = useState(false)
	const [logsModalIsOpen, setLogsModalIsOpen] = useState(false)
	const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)

	useEffect(() => {
		setColumns(COLUMNS(object.path, highLightJobs, highLightCompanies))
	}, [highLightJobs, highLightCompanies, object.path])

	const additionalColumns = (columns) => {
		return [
			{
				id: "selection",
				Header: ({ getToggleAllPageRowsSelectedProps }) => (
					<Checkbox {...getToggleAllPageRowsSelectedProps()} />
				),
				Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
			},
			{
				id: "update",
				Header: "",
				Cell: ({ row }) => (
					<Link
						onClick={() => {
							setUpdateModalIsOpen(true)
						}}
						to={{
							pathname: `/records/${object.path}/update/${row.original.id}`,
						}}
						state={{ id: row.original.id }}
					>
						<button style={{ width: "100%" }} className='update-btn'>
							update
						</button>
					</Link>
				),
			},
			...columns,
		]
	}

	const hiddenCols = () => {
		if (object.path === "jobs") {
			return ["id", "job_is_highlighted", "company_is_highlighted"]
		} else {
			return ["id", "_id"]
		}
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		setPageSize,
		visibleColumns,
		flatRows,
		prepareRow,
		selectedFlatRows,
		setFilter,
	} = useTable(
		{
			columns,
			data: records,
			initialState: {
				hiddenColumns: hiddenCols(),
				sortBy: [
					{
						id: "date",
						desc: true,
					},
				],
			},
		},
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				return additionalColumns(columns)
			})
		}
	)

	useEffect(() => {
		if (selectedFlatRows.length > 0) {
			setSelection(selectedFlatRows.map((row) => row.original.id))
		}
	}, [setSelection, selectedFlatRows])

	const handleFilterByHlJobs = () => {
		if (highLightJobs && !filterHighlightedJobs) {
			setFilter("job_is_highlighted", true)
		} else {
			setFilter("job_is_highlighted", "")
		}
		setFilterHighLightedJobs(!filterHighlightedJobs)
	}

	const handleFilterByHlCompanies = () => {
		if (highLightCompanies && !filterHighlightedCompanies) {
			setFilter("company_is_highlighted", true)
		} else {
			setFilter("company_is_highlighted", "")
		}
		setFilterHighLightedCompanies(!filterHighlightedCompanies)
	}

	/* prepare data for export */
	useEffect(() => {
		if (flatRows.length > 0 && flatRows[0].allCells !== undefined) {
			const allColumns = flatRows[0].allCells.map((cell) => cell.column.id)
			const columns = allColumns
				.map((column) => {
					if (
						column !== "selection" &&
						column !== "update" &&
						column !== "id"
					) {
						return { label: column, key: column }
					}
				})
				.filter((c) => c !== null && c !== undefined)
			const rowsData = flatRows.map((r) => r.original)
			prepareExport(columns, rowsData)
		}
	}, [flatRows, visibleColumns])

	const rangeSize = 200

	const getRangeOptions = () => {
		const ranges = []
		for (let i = 0; i < pagesCount; i += rangeSize) {
			const start = i + 1
			const end = Math.min(i + rangeSize, pagesCount)
			ranges.push(`${start}-${end}`)
		}
		return ranges
	}

	const getDisplayedPages = () => {
		const pages = []
		const start = rangeIndex * rangeSize
		const end = Math.min(start + rangeSize, pagesCount)
		for (let i = start; i < end; i++) {
			pages.push(i)
		}
		return pages
	}

	const handleRangeChange = (e) => {
		const newRangeIndex = Number(e.target.value)
		setRangeIndex(newRangeIndex)
		setPageIndex(newRangeIndex * rangeSize)
	}

	const handlePageChange = (e) => {
		setPageIndex(Number(e.target.value))
	}

	return (
		<React.Fragment>
			{object.path === "jobs" && (
				<fieldset className='highLightFilter-container'>
					<legend>Highlight jobs & companies</legend>
					<div className='filter-grid'>
						<div className='filter-flex'>
							<input
								className='switch-checkbox'
								id='switch-highlight-jobs'
								type='checkbox'
								checked={highLightJobs}
								onChange={() => {
									if (highLightJobs) {
										setFilter("job_is_highlighted", "")
										setFilterHighLightedJobs(false)
									}
									setHighLightJobs(!highLightJobs)
								}}
							/>
							<label className='switch-label' htmlFor='switch-highlight-jobs'>
								<span className='switch-button' />
							</label>
							Highlight jobs ON/OFF
						</div>
						<div className='filter-flex'>
							<input
								disabled={!highLightJobs}
								className='switch-checkbox'
								id='switch-filter-jobs'
								type='checkbox'
								checked={filterHighlightedJobs}
								onChange={handleFilterByHlJobs}
							/>
							<label className='switch-label' htmlFor='switch-filter-jobs'>
								<span className='switch-button' />
							</label>
							Filter by jobs
						</div>
					</div>
					<div className='filter-grid'>
						<div className='filter-flex'>
							<input
								className='switch-checkbox'
								id='switch-highlight-companies'
								type='checkbox'
								checked={highLightCompanies}
								onChange={() => {
									if (highLightCompanies) {
										setFilter("company_is_highlighted", "")
										setFilterHighLightedCompanies(false)
									}
									setHighLightCompanies(!highLightCompanies)
								}}
							/>
							<label
								className='switch-label'
								htmlFor='switch-highlight-companies'
							>
								<span className='switch-button' />
							</label>
							Highlight companies ON/OFF
						</div>
						<div className='filter-flex'>
							<input
								disabled={!highLightCompanies}
								className='switch-checkbox'
								id='switch-filter-companies'
								type='checkbox'
								checked={filterHighlightedCompanies}
								onChange={handleFilterByHlCompanies}
							/>
							<label className='switch-label' htmlFor='switch-filter-companies'>
								<span className='switch-button' />
							</label>
							Filter by companies
						</div>
					</div>
				</fieldset>
			)}
			<div className='table-options'>
				<div className='table-filters'>
					<div>
						Search:
						<input
							disabled={isLoading}
							value={searchText || ""}
							onChange={(e) => setSearchText(e.target.value)}
							style={{ outline: "none" }}
						/>
						<button
							disabled={isLoading}
							className='update-btn'
							style={{
								padding: ".2rem .5rem",
								marginLeft: ".5rem",
								borderRadius: "3px",
								width: "auto",
							}}
							onClick={() => {
								setPageIndex(0)
								setPageLimit(10)
								setRangeIndex(0)
								getRecordsWithPagination(object.findAllUrl, 10, 0, searchText)
							}}
						>
							search
						</button>
					</div>
					{object.path === "jobs" && (
						<Link
							className='addfilter-btn'
							onClick={() => {
								setFiltersModalIsOpen(true)
							}}
							to={`/records/${object.path}/editfilters`}
						>
							<FaFilter size='13px' /> <span>Add filters</span>
						</Link>
					)}
				</div>

				<div className='pagination'>
					<span>
						<select
							value={pageSize}
							onChange={(e) => {
								setPageSize(Number(e.target.value))
								setPageLimit(Number(e.target.value))
								setRangeIndex(0)
								setPageIndex(0)
							}}
						>
							{[10, 25, 50, 100, 500].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize} per page
								</option>
							))}
						</select>
					</span>
					<span>
						{pagesCount > 200 && (
							<select
								value={rangeIndex}
								onChange={handleRangeChange}
								style={{ marginRight: ".5rem" }}
							>
								{getRangeOptions().map((range, index) => (
									<option key={index} value={index}>
										{range}
									</option>
								))}
							</select>
						)}

						<select value={pageIndex} onChange={handlePageChange}>
							{getDisplayedPages().map((i) => (
								<option key={i} value={i}>
									page {i + 1}
								</option>
							))}
						</select>
						<span>of {pagesCount}</span>
					</span>{" "}
					<button
						onClick={() => setPageIndex(pageIndex - 1)}
						disabled={pageIndex === 0}
					>
						<GrFormPrevious size='16px' />
					</button>
					<button
						onClick={() => setPageIndex(pageIndex + 1)}
						disabled={pageIndex + 1 === pagesCount}
					>
						<GrFormNext size='16px' />
					</button>
				</div>
			</div>

			{isLoading ? (
				<Spinner />
			) : records.length < 1 ? (
				<div className='empty-records'>No records found.</div>
			) : (
				<table className='recs-table' {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			)}
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setUpdateModalIsOpen(false)}
				isOpen={updateModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<UpdateRecord handleClose={setUpdateModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setAccessModalIsOpen(false)}
				isOpen={accessModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<ClientAccess handleClose={setAccessModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-logs'
				overlayClassName='modal-logs-overlay'
				onRequestClose={() => setLogsModalIsOpen(false)}
				isOpen={logsModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<LogsModal handleClose={setLogsModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setFiltersModalIsOpen(false)}
				isOpen={filtersModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<AddFilters
					pageSize={pageSize}
					pageIndex={pageIndex}
					searchText={searchText}
					handleClose={setFiltersModalIsOpen}
				/>
			</ReactModal>
		</React.Fragment>
	)
}

TableWithPagination.prototype = {
	object: PropTypes.object.isRequired,
	records: PropTypes.array.isRequired,
	prepareExport: PropTypes.func.isRequired,
	pagesCount: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
	setPageIndex: PropTypes.number.isRequired,
	searchText: PropTypes.string,
	setSearchText: PropTypes.func.isRequired,
	getRecordsWithPagination: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	records: state.record.records,
	pagesCount: state.record.pagesCount,
})

export default connect(mapStateToProps, {
	prepareExport,
	getRecordsWithPagination,
})(TableWithPagination)
