import React, { Fragment } from 'react'
import spinner from './spinner.gif'

const Spinner = ({ spinnerSize = '200px' }) => {
	return (
		<Fragment>
			<img
				src={spinner}
				style={{ width: spinnerSize, margin: 'auto', display: 'block' }}
				alt='Loading...'
			/>
		</Fragment>
	)
}

export default Spinner
