const OBJECTS = [
	{
		path: "jobs",
		name: "Jobs",
		addUrl: "/jobs/add",
		findAllUrl: "/jobs/findall",
		delUrl: "/jobs/remove_many",
		updateUrl: "/jobs/update/",
		updateManyUrl: "/jobs/update_many",
		findUrl: "/jobs/find/",
		delField: "jobs_id",
		importUrl: "/import_jobs",
		filterUrl: "/jobs/filter",
		approveUrl: "jobs/approve",
		clearAllUrl: "/jobs/remove_all",
	},
	{
		path: "fuzzyjobs",
		name: "Fuzzy Jobs",
		findAllUrl: "/list_fuzzy_jobs",
		delUrl: "/drop_fuzzy_jobs",
		delField: "ids",
		importUrl: "/import_fuzzy_jobs",
		filterUrl: "/filter_fuzzy_jobs",
		clearAllUrl: "/clear_fuzzy_jobs",
	},
	{
		path: "jobtitlereplace",
		name: "Job Title Replace",
		addUrl: "/jobs/replace/add",
		findAllUrl: "/jobs/replace/findall",
		delUrl: "/jobs/replace/remove_many",
		updateUrl: "/jobs/replace/update/",
		updateManyUrl: "/jobs/replace/update_many",
		findUrl: "/jobs/replace/find/",
		delField: "jobs_id",
	},
	{
		path: "jobtitleblack",
		name: "Job Title Black",
		addUrl: "/jobs/black/add",
		findAllUrl: "/jobs/black/findall",
		delUrl: "/jobs/black/remove_many",
		updateUrl: "/jobs/black/update/",
		updateManyUrl: "/jobs/black/update_many",
		findUrl: "/jobs/black/find/",
		delField: "jobs_id",
	},
	{
		path: "jobtitlewhite",
		name: "Job Title White",
		addUrl: "/jobs/white/add",
		findAllUrl: "/jobs/white/findall",
		delUrl: "/jobs/white/remove_many",
		updateUrl: "/jobs/white/update/",
		updateManyUrl: "/jobs/white/update_many",
		findUrl: "/jobs/white/find/",
		delField: "jobs_id",
	},
	{
		path: "jobfiltering",
		name: "Job Rules",
		addUrl: "/job_filtering/add",
		findAllUrl: "/job_filtering/clients/findall",
		delUrl: "/job_filtering/remove_many",
		updateUrl: "/job_filtering/update/",
		updateManyUrl: "/jobs_filtering/update_many",
		findUrl: "/job_filtering/find/",
		delField: "rules_id",
	},
	{
		path: "jobtypes",
		name: "Job Types",
		addUrl: "/job_types/add",
		findAllUrl: "/job_types/findall",
		delUrl: "/job_types/remove_many",
		updateUrl: "/job_types/update/",
		findUrl: "/job_types/find/",
		delField: "job_types_id",
	},
	{
		path: "jobtypesreplace",
		name: "Job Types Replace",
		addUrl: "/job_types/replace/add",
		findAllUrl: "/job_types/replace/findall",
		delUrl: "/job_types/replace/remove_many",
		updateUrl: "/job_types/replace/update/",
		findUrl: "/job_types/replace/find/",
		delField: "job_types_id",
	},
	{
		path: "companies",
		name: "Companies",
		addUrl: "/companies/add",
		findAllUrl: "/companies/findall",
		delUrl: "/companies/remove_many",
		updateUrl: "/companies/update/",
		updateManyUrl: "/companies/update_many",
		findUrl: "/companies/find/",
		delField: "companies_id",
		importUrl: "/import_companies",
	},
	{
		path: "reports",
		name: "Reports",
		findAllUrl: "/reports/find_period",
	},
	{
		path: "companyreplace",
		name: "Company Replace",
		addUrl: "/companies/replace/add",
		findAllUrl: "/companies/replace/findall",
		delUrl: "/companies/replace/remove_many",
		updateUrl: "/companies/replace/update/",
		updateManyUrl: "/companies/replace/update_many",
		findUrl: "/companies/replace/find/",
		delField: "companies_id",
	},
	{
		path: "companyblack",
		name: "Company Black",
		addUrl: "/companies/black/add",
		findAllUrl: "/companies/black/findall",
		delUrl: "/companies/black/remove_many",
		updateUrl: "/companies/black/update/",
		updateManyUrl: "/companies/black/update_many",
		findUrl: "/companies/black/find/",
		delField: "companies_id",
	},
	{
		path: "companywhite",
		name: "Company White",
		addUrl: "/companies/white/add",
		findAllUrl: "/companies/white/findall",
		delUrl: "/companies/white/remove_many",
		updateUrl: "/companies/white/update/",
		updateManyUrl: "/companies/white/update_many",
		findUrl: "/companies/white/find/",
		delField: "companies_id",
	},
	{
		path: "sectors",
		name: "Sectors",
		addUrl: "/sectors/add",
		findAllUrl: "/sectors/findall",
		delUrl: "/sectors/remove_many",
		updateUrl: "/sectors/update/",
		findUrl: "/sectors/find/",
		delField: "sectors_id",
		importUrl: "/import_sectors",
	},
	{
		path: "location",
		name: "Location",
		addUrl: "/locations/add",
		findAllUrl: "/locations/findall",
		delUrl: "/locations/remove_many",
		updateUrl: "/locations/update/",
		updateManyUrl: "/locations/update_many",
		findUrl: "/locations/find/",
		delField: "locations_id",
		importUrl: "/import_locations",
	},
	{
		path: "locationreplace",
		name: "Location Replace",
		addUrl: "/locations/replace/add",
		findAllUrl: "/locations/replace/findall",
		delUrl: "/locations/replace/remove_many",
		updateUrl: "/locations/replace/update/",
		updateManyUrl: "/locations/replace/update_many",
		findUrl: "/locations/replace/find/",
		delField: "locations_id",
	},

	{
		path: "recruiters",
		name: "Recruiters",
		addUrl: "/recruiters/add",
		findAllUrl: "/recruiters/findall",
		delUrl: "/recruiters/remove_many",
		updateUrl: "/recruiters/update/",
		updateManyUrl: "/recruiters/update_many",
		findUrl: "/recruiters/find/",
		delField: "recuiters_id",
		importUrl: "/import_recruiters",
	},
	{
		path: "employees",
		name: "Employees",
		addUrl: "/users/add",
		findAllUrl: "/users/findall",
		delUrl: "/users/remove_many",
		updateUrl: "/users/update/",
		updateManyUrl: "/users/update_many",
		findUrl: "/users/find/",
		delField: "users_id",
		importUrl: "/import_users",
	},
	{
		path: "clients",
		name: "Clients",
		addUrl: "/clients/add",
		findAllUrl: "/clients/findall",
		delUrl: "/clients/remove_many",
		updateUrl: "/clients/update/",
		updateManyUrl: "/clients/update_many",
		findUrl: "/clients/find/",
		delField: "clients_id",
	},
]

export default OBJECTS
