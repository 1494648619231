import React, { useEffect, useMemo, useState } from "react"
import { GrFormNext, GrFormPrevious } from "react-icons/gr"
import ReactModal from "react-modal"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table"
import { getReports, prepareExport } from "../../../actions/record"
import Spinner from "../../layout/Spinner"
import AddFilters from "../AddFilters"
import ClientAccess from "../ClientAccess"
import UpdateRecord from "../UpdateRecord"
import LogsModal from "../logs/LogsModal"
import Checkbox from "./Checkbox"
import { COLUMNS } from "./Columns"
import GlobalFilter from "./GlobalFilter"
import "./table.css"

const RecordsTable = ({
	object,
	records,
	setSelection,
	prepareExport,
	isLoading,
	getReports,
	reportsStartDate,
	setReportsStartDate,
	reportsEndDate,
	setReportsEndDate,
}) => {
	const columns = useMemo(
		() => COLUMNS(object.path || "recruiters"),
		[object.path]
	)
	const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false)
	const [accessModalIsOpen, setAccessModalIsOpen] = useState(false)
	const [logsModalIsOpen, setLogsModalIsOpen] = useState(false)
	const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)

	const additionalColumns = (columns) => {
		if (object.path === "fuzzyjobs") {
			return [
				{
					id: "selection",
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<Checkbox {...getToggleAllPageRowsSelectedProps()} />
					),
					Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
				},
				...columns,
			]
		} else if (object.path === "clients") {
			return [
				{
					id: "selection",
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<Checkbox {...getToggleAllPageRowsSelectedProps()} />
					),
					Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
				},
				{
					id: "update",
					Header: "",
					Cell: ({ row }) => (
						<Link
							to={`/records/${object.path}/update/${row.original.id}`}
							state={{ id: row.original.id }}
						>
							<button
								className='update-btn'
								onClick={() => {
									setUpdateModalIsOpen(true)
								}}
							>
								update
							</button>
						</Link>
					),
				},
				{
					id: "authorization",
					Header: "",
					Cell: ({ row }) => (
						<Link
							onClick={() => {
								setAccessModalIsOpen(true)
							}}
							to={{
								pathname: `/records/${object.path}/access/${row.original.id}`,
							}}
							state={{ id: row.original.id }}
						>
							<button className='access-btn'>access</button>
						</Link>
					),
				},
				{
					id: "logs",
					Header: "",
					Cell: ({ row }) => (
						<Link
							onClick={() => {
								setLogsModalIsOpen(!logsModalIsOpen)
							}}
							to={{
								pathname: `/records/${object.path}/${row.original.id}/logs`,
							}}
							state={{ id: row.original.id }}
						>
							<button className='access-btn'>logs</button>
						</Link>
					),
				},
				...columns,
			]
		} else if (object.path === "reports") {
			return [...columns]
		} else {
			return [
				{
					id: "selection",
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<Checkbox {...getToggleAllPageRowsSelectedProps()} />
					),
					Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
				},
				{
					id: "update",
					Header: "",
					Cell: ({ row }) => (
						<Link
							onClick={() => {
								setUpdateModalIsOpen(true)
							}}
							to={{
								pathname: `/records/${object.path}/update/${row.original.id}`,
							}}
							state={{ id: row.original.id }}
						>
							<button style={{ width: "100%" }} className='update-btn'>
								update
							</button>
						</Link>
					),
				},
				...columns,
			]
		}
	}

	const hiddenCols = () => {
		if (object.path === "fuzzyjobs") {
			return [
				"id",
				"date",
				"company_working",
				"location_working",
				"sector.description",
				"job_type_obj.job_type",
				"url",
				"source",
				"status",
				"job_is_highlighted",
				"company_is_highlighted",
			]
		} else {
			return ["id", "_id"]
		}
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		visibleColumns,
		flatRows,
		prepareRow,
		selectedFlatRows,
		setGlobalFilter,
		state: { globalFilter, pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data: records,
			initialState: {
				hiddenColumns: hiddenCols(),
				sortBy: [
					{
						id: "date",
						desc: true,
					},
				],
			},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => {
				return additionalColumns(columns)
			})
		}
	)

	useEffect(() => {
		if (selectedFlatRows.length > 0) {
			setSelection(selectedFlatRows.map((row) => row.original.id))
		}
	}, [setSelection, selectedFlatRows])

	/* prepare data for export */
	useEffect(() => {
		if (flatRows.length > 0 && flatRows[0].allCells !== undefined) {
			const allColumns = flatRows[0].allCells.map((cell) => cell.column.id)
			const columns = allColumns
				.map((column) => {
					if (
						column !== "selection" &&
						column !== "update" &&
						column !== "id"
					) {
						return { label: column, key: column }
					}
				})
				.filter((c) => c !== null && c !== undefined)
			const rowsData = flatRows.map((r) => r.original)
			prepareExport(columns, rowsData)
		}
	}, [flatRows, visibleColumns])

	return (
		<React.Fragment>
			{object.path === "reports" && (
				<fieldset className='highLightFilter-container'>
					<form
						className='filter-flex reports-form'
						onSubmit={(e) => {
							e.preventDefault()
							getReports(object.findAllUrl, {
								start_date: reportsStartDate,
								end_date: reportsEndDate,
							})
						}}
					>
						<div className='filter-flex'>
							<label>Start date</label>
							<input
								type='date'
								value={reportsStartDate}
								max={reportsStartDate}
								onChange={(e) => setReportsStartDate(e.target.value)}
							/>
						</div>
						<div className='filter-flex'>
							<label>End date</label>
							<input
								type='date'
								value={reportsEndDate}
								onChange={(e) => setReportsEndDate(e.target.value)}
							/>
						</div>
						<input type='submit' value='Filter' />
					</form>
				</fieldset>
			)}
			<div className='table-options'>
				<div className='table-filters'>
					<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
				</div>

				{records.length > 10 && (
					<div className='pagination'>
						<span>
							<select
								value={pageSize}
								onChange={(e) => setPageSize(Number(e.target.value))}
							>
								{[10, 25, 50, 100, 500].map((pageSize) => (
									<option key={pageSize} value={pageSize}>
										{pageSize} per page
									</option>
								))}
							</select>
						</span>
						<span>
							<select
								value={pageIndex}
								onChange={(e) => gotoPage(Number(e.target.value))}
							>
								{[...Array(pageCount)].map((val, i) => (
									<option key={i} value={i}>
										page {i + 1}
									</option>
								))}
							</select>
							<span>of {pageOptions.length}</span>
						</span>{" "}
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							<GrFormPrevious size='16px' />
						</button>
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							<GrFormNext size='16px' />
						</button>
					</div>
				)}
			</div>
			{isLoading ? (
				<Spinner />
			) : records.length < 1 ? (
				<div className='empty-records'>No records found.</div>
			) : (
				<table className='recs-table' {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render("Header")}
										<span>
											{column.isSorted
												? column.isSortedDesc
													? " 🔽"
													: " 🔼"
												: ""}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row)
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render("Cell")}</td>
										)
									})}
								</tr>
							)
						})}
					</tbody>
				</table>
			)}

			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setUpdateModalIsOpen(false)}
				isOpen={updateModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<UpdateRecord handleClose={setUpdateModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setAccessModalIsOpen(false)}
				isOpen={accessModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<ClientAccess handleClose={setAccessModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-logs'
				overlayClassName='modal-logs-overlay'
				onRequestClose={() => setLogsModalIsOpen(false)}
				isOpen={logsModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<LogsModal handleClose={setLogsModalIsOpen} />
			</ReactModal>
			<ReactModal
				className='custom-modal-add'
				overlayClassName='modal-add-overlay'
				onRequestClose={() => setFiltersModalIsOpen(false)}
				isOpen={filtersModalIsOpen}
				shouldCloseOnOverlayClick={false}
			>
				<AddFilters handleClose={setFiltersModalIsOpen} />
			</ReactModal>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	records: state.record.records,
})

export default connect(mapStateToProps, { prepareExport, getReports })(
	RecordsTable
)
