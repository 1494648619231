import OBJECTS from '../OBJECTS'
import { CLEAR_OBJECT, OBJECT_ERROR, SELECT_OBJECT } from './types'

export const selectObject = (name, navigate) => (dispatch) => {
	const obj = OBJECTS.find((element) => element.path === name && element)
	if (obj !== undefined && obj !== null) {
		dispatch({ type: SELECT_OBJECT, payload: obj })
	} else {
		dispatch({ type: OBJECT_ERROR, payload: 'Object Not Found!' })
		dispatch({ type: CLEAR_OBJECT })
		navigate('/records')
	}
}
