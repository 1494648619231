import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { setAlert } from '../../actions/alert'
import { verifyOtp } from '../../actions/auth'
import Alert from '../layout/Alert'
import Spinner from '../layout/Spinner'

const OtpModal = ({
	handleClose,
	isAuthenticated,
	verifyOtp,
	setAlert,
	email,
}) => {
	const [otp, setOtp] = useState('')
	const [loading, setLoading] = useState(false)

	const handleOtpSubmit = (e) => {
		e.preventDefault()
		setLoading(true)
		if (otp !== '') {
			verifyOtp(otp.trim(), email)
		} else {
			setAlert('Otp required!', 'danger', 'Otp')
			setLoading(false)
		}
		setLoading(false)
	}

	if (isAuthenticated) {
		return <Navigate to='/records' />
	}

	return (
		<div className='otp-container'>
			<header>
				<h1>jobflo 2-step verification code</h1>
				<GrClose onClick={() => handleClose(false)} />
			</header>
			<form onSubmit={handleOtpSubmit}>
				<Alert element='Otp' />
				<h4>Verification code</h4>
				<input
					placeholder='6-digit code'
					type='text'
					value={otp}
					onChange={(e) => setOtp(e.target.value)}
					required
				></input>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					{loading ? (
						<Spinner />
					) : (
						<input type='submit' value='Confirm'></input>
					)}
				</div>
			</form>
		</div>
	)
}

OtpModal.propTypes = {
	verifyOtp: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
	email: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { verifyOtp, setAlert })(OtpModal)
