import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { updateManyRecords, updateRecord } from '../../../../actions/record'

const UpdateJobTypes = ({
	object,
	record,
	setAlert,
	updateRecord,
	updateManyRecords,
	selectedRecords,
	rowsToUpdate,
	handleClose,
}) => {
	const navigate = useNavigate()
	const [jobType, setJobType] = useState('')

	useEffect(() => {
		if (rowsToUpdate === 'one') {
			if (record !== null && record !== '') {
				setJobType(record.job_type)
			} else {
				navigate(-1)
			}
		}
	}, [record])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (rowsToUpdate === 'one') {
			if (jobType !== '') {
				const res = await updateRecord(object, record.id, { job_type: jobType })
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert('Missing field!', 'danger', 'UpdateRecord')
			}
		} else {
			const payload = {
				selected_records: selectedRecords,
				payload: { job_type: jobType },
			}
			const res = updateManyRecords(object, payload)
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Job Type</label>
			<input
				type='text'
				name='jobType'
				value={jobType}
				onChange={(e) => setJobType(e.target.value)}
				required
			></input>

			<input type='submit' value='Submit'></input>
		</form>
	)
}

UpdateJobTypes.prototype = {
	object: PropTypes.object.isRequired,
	record: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	updateRecord: PropTypes.func.isRequired,
	updateManyRecords: PropTypes.func.isRequired,
	rowsToUpdate: PropTypes.string.isRequired,
	selectedRecords: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record.record,
	selectedRecords: state.record.selectedRecords,
})

export default connect(mapStateToProps, {
	setAlert,
	updateRecord,
	updateManyRecords,
})(UpdateJobTypes)
