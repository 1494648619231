import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { initFilters } from "../../actions/filters"
import {
	getRecords,
	getRecordsWithPagination,
	getReports,
	selectRecords,
} from "../../actions/record"
import RecordsTable from "./records-table/RecordsTable"
import TableWithPagination from "./records-table/TableWithPagination"

const Records = ({
	object,
	record: { records, loading },
	getRecords,
	getReports,
	getRecordsWithPagination,
	selectRecords,
	initFilters,
}) => {
	const [selection, setSelection] = useState([])
	const [limit, setLimit] = useState(10)
	const [skip, setSkip] = useState(0)
	const [rangeIndex, setRangeIndex] = useState(0)
	const [searchText, setSearchText] = useState("")
	const [path, setPath] = useState("")
	const [reportsStartDate, setReportsStartDate] = useState()
	const [reportsEndDate, setReportsEndDate] = useState()

	useEffect(() => {
		if (selection.length > 0) {
			selectRecords(selection.map((id) => id))
		}
	}, [selection])

	useEffect(() => {
		if (object.path === "jobs") {
			initFilters()
		}
		if (
			object.path === "jobs" ||
			object.path === "companies" ||
			object.path === "location"
		) {
			if (path !== object.path) {
				getRecordsWithPagination(object.findAllUrl, 10, 0, "")
				setLimit(10)
				setSkip(0)
				setRangeIndex(0)
				setSearchText("")
				setPath(object.path)
			} else {
				getRecordsWithPagination(object.findAllUrl, limit, skip, searchText)
			}
		} else if (object.path === "reports") {
			const endDate = moment().format("YYYY-MM-DD")
			const startDate = moment().subtract(7, "days").format("YYYY-MM-DD")
			setReportsStartDate(startDate)
			setReportsEndDate(endDate)
			getReports(object.findAllUrl, {
				start_date: startDate,
				end_date: endDate,
			})
		} else {
			getRecords(object.findAllUrl)
		}
	}, [object, limit, skip])

	return (
		<div className='records-wrapper'>
			{object.path === "jobs" ||
			object.path === "companies" ||
			object.path === "location" ? (
				<TableWithPagination
					setSelection={setSelection}
					pageSize={limit}
					setPageLimit={setLimit}
					pageIndex={skip}
					setPageIndex={setSkip}
					setRangeIndex={setRangeIndex}
					rangeIndex={rangeIndex}
					searchText={searchText}
					setSearchText={setSearchText}
					isLoading={loading}
				/>
			) : (
				<RecordsTable
					setSelection={setSelection}
					isLoading={loading}
					reportsStartDate={reportsStartDate}
					setReportsStartDate={setReportsStartDate}
					reportsEndDate={reportsEndDate}
					setReportsEndDate={setReportsEndDate}
				/>
			)}
		</div>
	)
}

Records.prototype = {
	object: PropTypes.object.isRequired,
	getRecords: PropTypes.func.isRequired,
	getReports: PropTypes.func.isRequired,
	getRecordsWithPagination: PropTypes.func.isRequired,
	record: PropTypes.object.isRequired,
	initFilters: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	record: state.record,
})

export default connect(mapStateToProps, {
	getRecords,
	getReports,
	getRecordsWithPagination,
	selectRecords,
	initFilters,
})(Records)
