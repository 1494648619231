import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import { getFuzzyJobs } from '../../../actions/record'
import Alert from '../../layout/Alert'
import Spinner from '../../layout/Spinner'

const FuzzyJobsFilters = ({ handleClose, object, setAlert, getFuzzyJobs }) => {
	const navigate = useNavigate()
	const [jobTitleMatch, setJobTitleMatch] = useState(true)
	const [locationMatch, setLocationMatch] = useState(true)
	const [companyMatch, setCompanyMatch] = useState(true)
	const [jobTitleFuzzyLevel, setJobTitleFuzzyLevel] = useState(0)
	const [locationFuzzyLevel, setLocationFuzzyLevel] = useState(0)
	const [companyFuzzyLevel, setCompanyFuzzyLevel] = useState(0)
	const [loading, setLoading] = useState(false)

	const prepareData = () => {
		const formData = {
			jobtitle: parseInt(jobTitleFuzzyLevel),
			location: parseInt(locationFuzzyLevel),
			company: parseInt(companyFuzzyLevel),
		}
		if (jobTitleMatch) delete formData.jobtitle
		if (locationMatch) delete formData.location
		if (companyMatch) delete formData.company

		return formData
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const formData = {
			threshold_payload: prepareData(),
		}
		try {
			setLoading(true)
			await getFuzzyJobs(object, formData)
			handleClose(false)
		} catch (err) {
			setAlert(err.response.data, 'danger', 'FuzzyJobsFilters')
		} finally {
			setLoading(false)
		}
	}

	return (
		<div>
			<h2 className='fuzzy-job-title'>Fuzzy Jobs Filters</h2>
			<div>
				<Alert element='FuzzyJobsFilters' />
				<form onSubmit={handleSubmit}>
					<div className='fuzzy-jobs-flex'>
						<label className='fuzzy-job-field'>job title</label>
						<div className='fuzzy-match-level'>
							<input
								type='checkbox'
								id='jobTitleMatch'
								value={jobTitleMatch}
								onChange={() => {
									setJobTitleMatch(!jobTitleMatch)
								}}
							/>
							<label htmlFor='jobTitleMatch'>fuzzy match</label>
						</div>
						<div className='fuzzy-match-level'>
							<label>fuzzy level</label>
							<input
								type='number'
								id='jobTitleFuzzyLevel'
								value={jobTitleFuzzyLevel}
								min={0}
								max={100}
								onChange={(e) => {
									setJobTitleFuzzyLevel(e.target.value)
								}}
							/>
						</div>
					</div>
					<div className='fuzzy-jobs-flex'>
						<label className='fuzzy-job-field'>location</label>
						<div className='fuzzy-match-level'>
							<input
								type='checkbox'
								id='locationMatch'
								value={locationMatch}
								onChange={() => {
									setLocationMatch(!locationMatch)
								}}
							/>
							<label htmlFor='locationMatch'>fuzzy match</label>
						</div>
						<div className='fuzzy-match-level'>
							<label htmlFor='locationFuzzyLevel'>fuzzy level</label>
							<input
								type='number'
								id='locationFuzzyLevel'
								value={locationFuzzyLevel}
								min={0}
								max={100}
								onChange={(e) => {
									setLocationFuzzyLevel(e.target.value)
								}}
							/>
						</div>
					</div>
					<div className='fuzzy-jobs-flex'>
						<label className='fuzzy-job-field'>company</label>
						<div className='fuzzy-match-level'>
							<input
								type='checkbox'
								id='companyMatch'
								value={companyMatch}
								onChange={() => {
									setCompanyMatch(!companyMatch)
								}}
							/>
							<label htmlFor='companyMatch'>fuzzy match</label>
						</div>
						<div className='fuzzy-match-level'>
							<label htmlFor='companyFuzzyLevel'>fuzzy level</label>
							<input
								type='number'
								id='companyFuzzyLevel'
								value={companyFuzzyLevel}
								min={0}
								max={100}
								onChange={(e) => {
									setCompanyFuzzyLevel(e.target.value)
								}}
							/>
						</div>
					</div>
					<div className='fuzzy-jobs-footer'>
						{loading ? (
							<Spinner />
						) : (
							<>
								<input
									type='submit'
									value='Submit'
									className='fuzzy-jobs-btn'
								/>
								<button
									className='fuzzy-jobs-btn'
									onClick={() => {
										handleClose(false)
										navigate(-1)
									}}
								>
									Cancel
								</button>
							</>
						)}
					</div>
				</form>
			</div>
		</div>
	)
}

FuzzyJobsFilters.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	getFuzzyJobs: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, getFuzzyJobs })(
	FuzzyJobsFilters
)
