import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'

const AddUser = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		password: '',
		confirm_password: '',
		role: '',
		status: '',
	})

	const {
		first_name,
		last_name,
		email,
		password,
		confirm_password,
		role,
		status,
	} = formData

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value })
	}

	const prepareData = () => {
		return {
			first_name: first_name,
			last_name: last_name,
			email: email,
			password: password,
			confirm_password: confirm_password,
			role: role,
			status: status,
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (
			first_name !== '' &&
			last_name !== '' &&
			email !== '' &&
			password !== '' &&
			confirm_password !== '' &&
			status !== '' &&
			role !== ''
		) {
			if (password === confirm_password) {
				const res = await addRecord(object, prepareData())
				if (res === true) {
					handleClose(false)
					navigate(`/records/${object.path}`)
				}
			} else {
				setAlert(
					"Repeat password and Password doesn't match",
					'danger',
					'AddRecord'
				)
			}
		} else {
			setAlert('Missing fields!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
					<label>First Name</label>
					<input
						type='text'
						name='first_name'
						value={first_name}
						onChange={handleChange}
						required
					/>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', width: '45%' }}>
					<label>Last Name</label>
					<input
						type='text'
						name='last_name'
						value={last_name}
						onChange={handleChange}
						required
					/>
				</div>
			</div>
			<label>Email</label>
			<input
				type='email'
				name='email'
				value={email}
				onChange={handleChange}
				required
			/>
			<label>Password</label>
			<input
				type='password'
				name='password'
				value={password}
				onChange={handleChange}
				required
			/>
			<label>Repeat Password</label>
			<input
				type='password'
				name='confirm_password'
				value={confirm_password}
				onChange={handleChange}
				required
			/>
			<label>Role</label>
			<select name='role' onChange={handleChange} required>
				<option disabled selected value>
					{' '}
					-- select an option --{' '}
				</option>
				<option value='admin'>Admin</option>
				<option value='employee'>Employee</option>
			</select>
			<label>Status</label>
			<select name='status' onChange={handleChange} required>
				<option disabled selected value>
					{' '}
					-- select an option --{' '}
				</option>
				<option value='active'>Active</option>
				<option value='inactive'>Inactive</option>
			</select>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddUser.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(AddUser)
