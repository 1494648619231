import { CLEAR_OBJECT, OBJECT_ERROR, SELECT_OBJECT } from '../actions/types'

const initialState = {
	info: {
		path: null,
		name: null,
		addUrl: null,
		findAllUrl: null,
		delUrl: null,
		updateUrl: null,
		findUrl: null,
		delField: null,
		importUrl: null,
	},
	loading: true,
	error: null,
}

export default function foo(state = initialState, action) {
	const { type, payload } = action
	switch (type) {
		case SELECT_OBJECT:
			return {
				...state,
				info: payload,
				loading: false,
			}
		case CLEAR_OBJECT:
			return {
				...state,
				info: {
					path: null,
					name: null,
					addUrl: null,
					findAllUrl: null,
					delUrl: null,
					updateUrl: null,
					findUrl: null,
					delField: null,
					importUrl: null,
				},
				loading: true,
				error: null,
			}
		case OBJECT_ERROR:
			return {
				...state,
				loading: false,
				error: payload,
			}
		default:
			return state
	}
}
