import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getRecord } from '../../actions/record'
import Alert from '../layout/Alert'
import UpdateClient from './clients/update-forms/UpdateClient'
import UpdateCompany from './companies/update-forms/UpdateCompany'
import UpdateCompanyBlack from './companies/update-forms/UpdateCompanyBlack'
import UpdateCompanyReplace from './companies/update-forms/UpdateCompanyReplace'
import UpdateCompanyWhite from './companies/update-forms/UpdateCompanyWhite'
import UpdateUser from './employees/update-forms/UpdateUser'
import UpdateJob from './jobs/update-forms/UpdateJob'
import UpdateJobBlack from './jobs/update-forms/UpdateJobBlack'
import UpdateJobFiltering from './jobs/update-forms/UpdateJobFiltering'
import UpdateJobTitleReplace from './jobs/update-forms/UpdateJobTitleReplace'
import UpdateJobTypes from './jobs/update-forms/UpdateJobTypes'
import UpdateJobTypesReplace from './jobs/update-forms/UpdateJobTypesReplace'
import UpdateJobWhite from './jobs/update-forms/UpdateJobWhite'
import UpdateLocation from './locations/update-forms/UpdateLocation'
import UpdateLocationReplace from './locations/update-forms/UpdateLocationReplace'
import UpdateRecruiter from './recruiters/update-forms/UpdateRecruiter'
import UpdateSector from './sectors/update-forms/UpdateSector'

const UpdateRecord = ({
	object,
	handleClose,
	getRecord,
	rowsToUpdate = 'one',
}) => {
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		if (rowsToUpdate === 'one') {
			if (!location.state.id) navigate(`/records/${object.path}`)
			getRecord(object.findUrl, location.state.id)
		}
	}, [object])

	const renderElement = () => {
		switch (object.path) {
			case 'clients':
				return (
					<UpdateClient rowsToUpdate={rowsToUpdate} handleClose={handleClose} />
				)
			case 'jobs':
				return (
					<UpdateJob rowsToUpdate={rowsToUpdate} handleClose={handleClose} />
				)
			case 'jobtitleblack':
				return (
					<UpdateJobBlack
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'jobtitlewhite':
				return (
					<UpdateJobWhite
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'jobtitlereplace':
				return (
					<UpdateJobTitleReplace
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'jobfiltering':
				return (
					<UpdateJobFiltering
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'jobtypes':
				return (
					<UpdateJobTypes
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'jobtypesreplace':
				return (
					<UpdateJobTypesReplace
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'sectors':
				return (
					<UpdateSector rowsToUpdate={rowsToUpdate} handleClose={handleClose} />
				)
			case 'companies':
				return (
					<UpdateCompany
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'companyblack':
				return (
					<UpdateCompanyBlack
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'companywhite':
				return (
					<UpdateCompanyWhite
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'companyreplace':
				return (
					<UpdateCompanyReplace
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'location':
				return (
					<UpdateLocation
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'locationreplace':
				return (
					<UpdateLocationReplace
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'recruiters':
				return (
					<UpdateRecruiter
						rowsToUpdate={rowsToUpdate}
						handleClose={handleClose}
					/>
				)
			case 'employees':
				return (
					<UpdateUser rowsToUpdate={rowsToUpdate} handleClose={handleClose} />
				)
			default:
				return <div style={{ textAlign: 'center' }}>No such object</div>
		}
	}

	return (
		<div className='add-record-container'>
			<header>
				<h1>Update {object.name}</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose(false)
						navigate(`/records/${object.path}`)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<Alert element='UpdateRecord' />
			{renderElement()}
		</div>
	)
}

UpdateRecord.prototype = {
	object: PropTypes.object.isRequired,
	getRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { getRecord })(UpdateRecord)
