import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import { FaCubes, FaUsers } from "react-icons/fa"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { selectObject } from "../../actions/object"
import MyObject from "./MyObject"

const RecordsOptions = ({ object, auth, selectObject }) => {
	const navigate = useNavigate()
	const [toggleJob, setToggleJob] = useState(false)
	const [toggleCompany, setToggleCompany] = useState(false)
	const [toggleAccount, setToggleAccount] = useState(false)
	const [toggleLocation, setToggleLocation] = useState(false)

	const handleClick = (name) => {
		selectObject(name, navigate)
	}

	return (
		<Fragment>
			<div className='objects-container'>
				<div className='objects-title'>
					<h3>
						<FaCubes size='18px' className='nav-options-icon' /> Objects
					</h3>
				</div>
				<div className='objects nested-obj'>
					<MyObject
						key={1}
						name='Jobs'
						path='jobs'
						nested={true}
						toggle={{ toggled: toggleJob, handleToggle: setToggleJob }}
						handleClick={handleClick}
						selected={object.path === "jobs" ? true : false}
					/>
					<ul style={{ display: !toggleJob && "none" }}>
						<li>
							<MyObject
								key={2}
								name='Job Title Replace'
								path='jobtitlereplace'
								handleClick={handleClick}
								selected={object.path === "jobtitlereplace" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={3}
								name='Job Title Black'
								path='jobtitleblack'
								handleClick={handleClick}
								selected={object.path === "jobtitleblack" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={4}
								name='Job Title White'
								path='jobtitlewhite'
								handleClick={handleClick}
								selected={object.path === "jobtitlewhite" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={55}
								name='Job Types'
								path='jobtypes'
								handleClick={handleClick}
								selected={object.path === "jobtypes" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={66}
								name='Job Types Replace'
								path='jobtypesreplace'
								handleClick={handleClick}
								selected={object.path === "jobtypesreplace" ? true : false}
							/>
						</li>
						{auth.user !== null && auth.user.role === "admin" && (
							<li>
								<MyObject
									key={44}
									name='Job Rules'
									path='jobfiltering'
									handleClick={handleClick}
									selected={object.path === "jobfiltering" ? true : false}
								/>
							</li>
						)}
						<li>
							<MyObject
								key={111}
								name='Fuzzy Jobs'
								path='fuzzyjobs'
								handleClick={handleClick}
								selected={object.path === "fuzzyjobs" ? true : false}
							/>
						</li>
					</ul>

					<MyObject
						key={15}
						name='Sectors'
						path='sectors'
						handleClick={handleClick}
						selected={object.path === "sectors" ? true : false}
					/>

					<MyObject
						key={5}
						name='Companies'
						path='companies'
						nested={true}
						toggle={{ toggled: toggleCompany, handleToggle: setToggleCompany }}
						handleClick={handleClick}
						selected={object.path === "companies" ? true : false}
					/>
					<ul style={{ display: !toggleCompany && "none" }}>
						<li>
							<MyObject
								key={16}
								name='Reports'
								path='reports'
								handleClick={handleClick}
								selected={object.path === "reports" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={6}
								name='Company Replace'
								path='companyreplace'
								handleClick={handleClick}
								selected={object.path === "companyreplace" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={7}
								name='Company Black '
								path='companyblack'
								handleClick={handleClick}
								selected={object.path === "companyblack" ? true : false}
							/>
						</li>
						<li>
							<MyObject
								key={8}
								name='Company White'
								path='companywhite'
								handleClick={handleClick}
								selected={object.path === "companywhite" ? true : false}
							/>
						</li>
					</ul>
					{auth.user !== null && auth.user.role === "admin" && (
						<Fragment>
							<MyObject
								key={9}
								name='Location'
								path='location'
								nested={true}
								toggle={{
									toggled: toggleLocation,
									handleToggle: setToggleLocation,
								}}
								handleClick={handleClick}
								selected={object.path === "location" ? true : false}
							/>
							<ul style={{ display: !toggleLocation && "none" }}>
								<li>
									<MyObject
										key={10}
										name='Location Replace'
										path='locationreplace'
										handleClick={handleClick}
										selected={object.path === "locationreplace" ? true : false}
									/>
								</li>
							</ul>
							<MyObject
								key={11}
								name='Recruiters'
								path='recruiters'
								handleClick={handleClick}
								selected={object.path === "recruiters" ? true : false}
							/>{" "}
						</Fragment>
					)}
				</div>
			</div>
			{auth.user !== null && auth.user.role === "admin" && (
				<div className='users-container'>
					<div className='objects-title'>
						<h3>
							<FaUsers size='18px' className='nav-options-icon' /> Users Roles
						</h3>
					</div>
					<div className='objects nested-obj'>
						<MyObject
							key={12}
							name='Accounts'
							path='accounts'
							nested={true}
							toggle={{
								toggled: toggleAccount,
								handleToggle: setToggleAccount,
							}}
							selected={object.path === "accounts" ? true : false}
						/>
						<ul style={{ display: !toggleAccount && "none" }}>
							<li>
								<MyObject
									key={13}
									name='Employees'
									path='employees'
									handleClick={handleClick}
									selected={object.path === "employees" ? true : false}
								/>
							</li>
							<li>
								<MyObject
									key={14}
									name='Clients'
									path='clients'
									handleClick={handleClick}
									selected={object.path === "clients" ? true : false}
								/>
							</li>
						</ul>
					</div>
				</div>
			)}
		</Fragment>
	)
}

RecordsOptions.prototype = {
	object: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
	selectObject: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
	auth: state.auth,
})

export default connect(mapStateToProps, { selectObject })(RecordsOptions)
