import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAlert } from '../../../../actions/alert'
import { addRecord } from '../../../../actions/record'

const AddSector = ({ object, setAlert, addRecord, handleClose }) => {
	const navigate = useNavigate()
	const [description, setDescription] = useState('')
	const [sic_code, setSic_code] = useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (description !== '') {
			const res = await addRecord(object, {
				description,
				sic_code,
			})
			if (res === true) {
				handleClose(false)
				navigate(`/records/${object.path}`)
			}
		} else {
			setAlert('Missing field!', 'danger', 'AddRecord')
		}
	}

	return (
		<form className='add-record-form' onSubmit={handleSubmit}>
			<label>Description</label>
			<input
				type='text'
				name='description'
				value={description}
				onChange={(e) => setDescription(e.target.value)}
				required
			></input>
			<label>Sic Code</label>
			<input
				type='text'
				name='sic_code'
				value={sic_code}
				onChange={(e) => setSic_code(e.target.value)}
			></input>
			<input type='submit' value='Submit'></input>
		</form>
	)
}

AddSector.prototype = {
	object: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
	addRecord: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	object: state.object.info,
})

export default connect(mapStateToProps, { setAlert, addRecord })(AddSector)
