import PropTypes from 'prop-types'
import React from 'react'
import { GrClose } from 'react-icons/gr'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAllRecords, removeRecords } from '../../actions/record'
import Alert from '../layout/Alert'

const DeleteRecord = ({
	handleClose,
	clearAll,
	object,
	selectedRecords,
	removeRecords,
	clearAllRecords,
}) => {
	const navigate = useNavigate()

	const delRecords = async () => {
		if (clearAll === true) {
			const res = await clearAllRecords(object)
			if (res) {
				handleClose()
				navigate(`/records/${object.path}`)
			}
		} else {
			if (selectedRecords.length > 0) {
				const res = await removeRecords(object, selectedRecords)
				if (res) {
					handleClose()
					navigate(`/records/${object.path}`)
				}
			}
		}
	}

	const content = () => {
		if (clearAll === true) {
			return <h2>Are you sure you want to delete all records ?</h2>
		} else {
			if (selectedRecords.length > 0) {
				return <h2>Are you sure you want to delete selected records ?</h2>
			} else {
				return <h2>No selected records to delete.</h2>
			}
		}
	}

	return (
		<div className='delete-record-container'>
			<header>
				<h1 style={{ color: 'red' }}>Delete records</h1>
				<button
					className='close-btn'
					onClick={() => {
						handleClose()
						navigate(`/records/${object.path}`)
					}}
				>
					<GrClose size='18px' />
				</button>
			</header>
			<div>
				<Alert element='DeleteRecord' />
				{content()}
			</div>
			<footer>
				{selectedRecords.length > 0 || clearAll ? (
					<button style={{ backgroundColor: '#dc3545' }} onClick={delRecords}>
						Confirm
					</button>
				) : (
					<button
						style={{ backgroundColor: '#6c757d', minWidth: '65px' }}
						onClick={() => {
							handleClose()
							navigate(`/records/${object.path}`)
						}}
					>
						Ok
					</button>
				)}
				<button
					style={{ backgroundColor: '#6c757d' }}
					onClick={() => {
						handleClose()
						navigate(`/records/${object.path}`)
					}}
				>
					Cancel
				</button>
			</footer>
		</div>
	)
}

DeleteRecord.prototype = {
	object: PropTypes.object.isRequired,
	selectedRecords: PropTypes.array.isRequired,
	removeRecords: PropTypes.func.isRequired,
	clearAllRecords: PropTypes.func.isRequired,
	clearAll: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
	selectedRecords: state.record.selectedRecords,
	object: state.object.info,
})

export default connect(mapStateToProps, { removeRecords, clearAllRecords })(
	DeleteRecord
)
