import React, { useEffect, useState } from 'react'
import { FaCog, FaDatabase } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
	const location = useLocation()
	const [option, setOption] = useState('')

	useEffect(() => {
		if (location.pathname.includes('/records')) {
			setOption('records')
		} else if (location.pathname.includes('/settings')) {
			setOption('settings')
		}
	}, [location])

	return (
		<div className='sidebar'>
			<Link
				className={option === 'records' ? 'transition selected' : 'transition'}
				to='/records'
			>
				<FaDatabase size='20px' />
				<span>Records</span>
			</Link>
			<Link
				className={option === 'settings' ? 'transition selected' : 'transition'}
				to='/settings'
			>
				<FaCog size='20px' />
				<span>Settings</span>
			</Link>
		</div>
	)
}

export default Sidebar
